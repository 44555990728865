import axios from "axios";

const siteDomain = localStorage.getItem("tg-site-domain");

export const readAllReviews = async () => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/review/read_all.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const updateReviewState = async ({ reviewId, state }) => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("reviewId", reviewId);
    formData.append("state", state);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/review/update_review_state.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readAllReviewsByArticleId = async ({ articleId }) => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("articleId", articleId);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/review/read_by_article_id_backend.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const addReviewByArticleId = async ({
  articleId,
  name,
  comment,
  rating,
}) => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("articleId", articleId);
    formData.append("name", name);
    formData.append("comment", comment);
    formData.append("rating", rating);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/review/add_admin_review.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const getReviewList = async (word) => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("q", word);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/review/read_list.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
