import axios from "axios";

const siteDomain = localStorage.getItem("tg-site-domain");

export const readAllSnippets = async () => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/snippet/read_all.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const getUsedSnippetList = async (word) => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("q", word);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/snippet/read_list.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const addNewSnippet = async ({
  name,
  subTxt1,
  subTxt2,
  subTxt3,
  subTxt4,
  promoImg,
  img1,
  img2,
  type,
}) => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("name", name);
    formData.append("subTxt1", subTxt1);
    formData.append("subTxt2", subTxt2);
    formData.append("subTxt3", subTxt3);
    formData.append("subTxt4", subTxt4);
    formData.append("promoImg", promoImg);
    formData.append("img1", img1);
    formData.append("img2", img2);
    formData.append("type", type);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/snippet/create.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const addNewCustomSnippet = async ({ name, templateBody, type }) => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("name", name);
    formData.append("templateBody", templateBody);
    formData.append("type", type);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/snippet/custom_create.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readBySnippetId = async ({ snippetId }) => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("snippetId", snippetId);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/snippet/read_by_id.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const updateCustomSnippet = async ({
  snippetId,
  name,
  templateBody,
  type,
}) => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("snippetId", snippetId);
    formData.append("name", name);
    formData.append("templateBody", templateBody);
    formData.append("type", type);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/snippet/custom_update.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const updateNormalSnippet = async ({
  snippetId,
  name,
  subTxt1,
  subTxt2,
  subTxt3,
  subTxt4,
  promoImg,
  img1,
  img2,
  type,
}) => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("snippetId", snippetId);
    formData.append("name", name);
    formData.append("subTxt1", subTxt1);
    formData.append("subTxt2", subTxt2);
    formData.append("subTxt3", subTxt3);
    formData.append("subTxt4", subTxt4);
    formData.append("promoImg", promoImg);
    formData.append("img1", img1);
    formData.append("img2", img2);
    formData.append("type", type);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/snippet/update.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
