import { useState } from "react";

import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

import "./HtmlEditor.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function HtmlEditor({ templateBodyChange, initialValue = "" }) {
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(initialValue))
    )
  );
  const [htmlCode, setHtmlCode] = useState(initialValue);
  const [prevMode, setPrevMode] = useState(true);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setHtmlCode(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    templateBodyChange(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  const onEditorHtmlCodeStateChange = (e) => {
    setHtmlCode(e.target.value);
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(e.target.value))
      )
    );
    templateBodyChange(e.target.value);
  };

  function uploadImageCallBack(file) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.open(
        "POST",
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_PUBLIC_CLOUDINARY_CLOUDNAME}/upload`
      );
      xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

      const data = new FormData();

      data.append(
        "upload_preset",
        process.env.REACT_APP_PUBLIC_CLOUDINARY_UNSIGNED_UPLOAD_PRESET
      );
      data.append("tags", "browser_upload");
      data.append("file", file);
      xhr.send(data);
      xhr.addEventListener("load", () => {
        const response = JSON.parse(xhr.responseText);
        resolve({ data: { link: response.url } });
      });
      xhr.addEventListener("error", () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    });
  }

  return (
    <div>
      <div className="text-btn-head mb-2">
        <p
          className={
            prevMode ? "ui blue circular label" : "ui red circular label"
          }
          onClick={() => {
            setPrevMode(true);
          }}
        >
          Prev
        </p>
        <p
          className={
            !prevMode ? "ui blue circular label" : "ui red circular label"
          }
          onClick={() => {
            setPrevMode(false);
          }}
        >
          Code
        </p>
      </div>

      {prevMode && (
        <div className="editor">
          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            toolbar={{
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
              image: {
                uploadCallback: uploadImageCallBack,
                alt: { present: false, mandatory: false },
                defaultSize: {
                  height: "150",
                  width: "200",
                },
              },
            }}
          />
        </div>
      )}
      {!prevMode && (
        <textarea
          className="form-control"
          rows={10}
          value={htmlCode}
          onChange={onEditorHtmlCodeStateChange}
        />
      )}
    </div>
  );
}

export default HtmlEditor;
