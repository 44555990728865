import { useField, useFormikContext } from "formik";
import { useState } from "react";
import { Image } from "semantic-ui-react";

import "./FormikUploadImgInput.css";

function FormikUploadImgInput({ label, ...props }) {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const [imgUrl, setImgUrl] = useState(props.imgSrc);

  const inputImgChange = (event) => {
    setFieldValue(field.name, event.currentTarget.files[0]);
    setImgUrl(URL.createObjectURL(event.currentTarget.files[0]));
  };

  return (
    <>
      {imgUrl && <Image className="formik-imp-upload" src={imgUrl} alt="img" />}
      <input
        name={props.name}
        accept="image/*"
        type="file"
        onChange={(event) => {
          inputImgChange(event);
        }}
      />
    </>
  );
}

export default FormikUploadImgInput;
