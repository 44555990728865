import React, { useState } from "react";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  useField,
  useFormikContext,
} from "formik";
import * as Yup from "yup";
import { Alert, FormGroup } from "reactstrap";
import ReactStars from "react-rating-stars-component";

import "./AddReviewForm.css";
import { addReviewByArticleId } from "../../../../api/reviewAPI";

const TextArea = ({ label, ...props }) => {
  const [field] = useField(props);
  return (
    <>
      <textarea className="form-control" {...field} {...props} />
    </>
  );
};

const ReactStarsFormik = ({ label, ...props }) => {
  const { setFieldValue } = useFormikContext();
  return (
    <ReactStars
      count={5}
      size={24}
      activeColor="#ffd700"
      onChange={(value) => {
        setFieldValue("rating", value);
      }}
    />
  );
};

const AddReviewForm = ({ setOpenAddReviewModal, articleId }) => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);

  function successSubmission() {
    setOpenAddReviewModal(false);
    //window.location.href = `/product-review-page/${articleId}`;
  }

  function failSubmission(head, sub) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-sm-12">
          <div className="">
            <Formik
              initialValues={{
                name: "",
                comment: "",
                rating: "",
              }}
              validationSchema={Yup.object({
                name: Yup.string()
                  .min(5, "Name cannot smaller than 5 characters")
                  .max(70, "Name cannot exceed 70 characters")
                  .required("Required"),
                comment: Yup.string()
                  .min(20, "Comment cannot smaller than 20 characters")
                  .max(250, "Comment cannot exceed 250 characters")
                  .required("Required"),
                rating: Yup.number()
                  .min(1)
                  .max(5, "Too Long!")
                  .required("Required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                setIsFailSubmission(false);

                addReviewByArticleId({
                  articleId: articleId,
                  name: values.name,
                  comment: values.comment,
                  rating: values.rating,
                }).then((data) => {
                  try {
                    if (data["result"]) {
                      if (data["result"] === "SUCCESS") {
                        successSubmission(data["data"]);
                      } else if (data["result"] === "FAILED") {
                        failSubmission("¡Error!", data["data"]["errorMessage"]);
                      } else {
                        failSubmission("Error!", "Please try again later!");
                      }
                    } else {
                      failSubmission("Error!", "Please try again later!");
                    }
                  } catch (error) {
                    failSubmission("Error!", "Please try again later!");
                  }
                });
              }}
            >
              <Form>
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-8 mt-4 mb-4">
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="name">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <ReactStarsFormik />
                        <ErrorMessage name="rating">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <TextArea
                          name="comment"
                          rows="6"
                          placeholder="Enter comment"
                        />
                        <ErrorMessage name="comment">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                    {isFailSubmission && (
                      <div className="container">
                        <div className="row">
                          <div className="col text-center">
                            <>
                              <Alert color="danger">
                                <p className="mb-0">{errorMsg.sub}</p>
                              </Alert>
                            </>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12 text-center">
                          <button
                            type="submit"
                            className="button-submit-style"
                            disabled={isLoading}
                          >
                            Add Review
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddReviewForm;
