import { useMemo } from "react";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiOutlineUserAdd } from "react-icons/ai";
import { HiViewList } from "react-icons/hi";
import { FaEdit } from "react-icons/fa";

import { readAllProductReviewPages } from "../../../../api/productReviewAPI";

import "./AllProductReviewPages.css";

const AllProductReviewPages = () => {
  const [articleItems, setArticleItems] = useState([]);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    readAllProductReviewPages().then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setArticleItems(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [articleItems.length]);

  const columns = useMemo(
    () => [
      {
        name: "",
        selector: (row) => {
          return (
            <img
              className="product-review-head-img"
              src={`${localStorage.getItem(
                "tg-site-domain"
              )}/content/uploads/img/${row.productImg}`}
              alt="head"
            />
          );
        },
        grow: 1,
      },
      {
        name: "Title",
        selector: (row) => row.title,
        sortable: true,
        grow: 4,
      },
      {
        name: "Created",
        selector: (row) => row.created,
        sortable: true,
      },
      {
        name: "",
        selector: (row) => {
          return (
            <>
              <a
                className="btn-link-style product-review-icon"
                href={`${localStorage.getItem(
                  "tg-site-domain"
                )}/product_review/${row.slug}`}
                target="_blank"
                rel="noReferrer"
              >
                <HiViewList />
              </a>
              <a
                className="btn-link-style product-review-icon"
                href={`/product-review-page/${row.productReviewId}`}
              >
                <FaEdit />
              </a>
            </>
          );
        },
      },
    ],
    []
  );

  const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      style: {
        fontSize: "16px",
      },
    },
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <button
            type="button"
            className="button-add-style"
            onClick={() => {
              window.location.href = "/add-product-review";
            }}
          >
            <AiOutlineUserAdd /> Add a Product Review Page
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="table_outer_div">
            <DataTable
              title="Product Review Pages"
              data={articleItems}
              columns={columns}
              fixedHeader
              fixedHeaderScrollHeight="100%"
              pagination
              progressPending={pending}
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllProductReviewPages;
