import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert, FormGroup } from "reactstrap";

import { updateReviewState } from "../../../../api/reviewAPI";

import "./EditReviewState.css";

const EditReviewState = ({ reviewObject, setOpenEditModal, path }) => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);

  function successSubmission() {
    setOpenEditModal(false);
    window.location.href = path;
  }

  function failSubmission(head, sub) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-sm-12">
          <div className="invoice-form-div">
            <Formik
              initialValues={{
                reviewId: reviewObject && reviewObject.reviewId,
                state: reviewObject && reviewObject.state,
              }}
              validationSchema={Yup.object({
                state: Yup.string().required("Required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                setIsFailSubmission(false);
                updateReviewState({
                  reviewId: values.reviewId,
                  state: values.state,
                }).then((data) => {
                  try {
                    if (data["result"]) {
                      if (data["result"] === "SUCCESS") {
                        successSubmission(data["data"]);
                      } else if (data["result"] === "FAILED") {
                        failSubmission("¡Error!", data["data"]["errorMessage"]);
                      } else {
                        failSubmission("Error!", "Please try again later!");
                      }
                    } else {
                      failSubmission("Error!", "Please try again later!");
                    }
                  } catch (error) {
                    failSubmission("Error!", "Please try again later!");
                  }
                });
              }}
            >
              <Form>
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-8 mb-4">
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="state"
                            as="select"
                            className="form-control"
                            placeholder="Select review State"
                            disabled={isLoading}
                          >
                            <option value="PUBLIC">PUBLIC</option>
                            <option value="PRIVATE">PRIVATE</option>
                          </Field>
                          <ErrorMessage name="state">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    {isFailSubmission && (
                      <div className="container">
                        <div className="row">
                          <div className="col text-center">
                            <>
                              <Alert color="danger">
                                {/* <p>{errorMsg.head}</p>
                                <hr /> */}
                                <p className="mb-0">{errorMsg.sub}</p>
                              </Alert>
                            </>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12 text-center">
                          <button
                            type="submit"
                            className="button-submit-style"
                            disabled={isLoading}
                          >
                            Update Review State
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditReviewState;
