import { useMemo } from "react";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { FaEdit } from "react-icons/fa";
import ReactStars from "react-rating-stars-component";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import { readAllReviews } from "../../../../api/reviewAPI";
import { renderReviewState } from "../../../../meta/other";
import EditReviewState from "../../forms/editReviewState/EditReviewState";

import "./AllReviews.css";

const AllReviews = () => {
  const [reviewItems, setReviewItems] = useState([]);
  const [pending, setPending] = useState(true);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [clickedReview, setClickedReview] = useState();

  const reviewEditClick = (value) => {
    setOpenEditModal(true);
    setClickedReview(value);
  };

  useEffect(() => {
    readAllReviews().then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setReviewItems(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [reviewItems.length]);

  const columns = useMemo(
    () => [
      {
        name: "",
        selector: (row) => {
          return (
            <div className="review-name-div">
              <ReactStars
                value={row.rating && row.rating}
                count={5}
                size={24}
                activeColor="#ffd700"
                edit={false}
              />
              <h4>{row.name}</h4>
              <p>{row.email}</p>
              <span>{row.ip}</span>
              <p className="gray">
                <b>{row?.article?.title}</b>
              </p>
            </div>
          );
        },
        grow: 2,
      },
      {
        name: "Comment",
        selector: (row) => {
          return (
            <textarea
              className="review-box form-control"
              name="comment"
              rows="8"
              value={row.comment}
              disabled
            />
          );
        },
        sortable: true,
        grow: 4,
      },
      {
        name: "",
        selector: (row) => renderReviewState(row.state),
        sortable: true,
        grow: 1,
      },
      {
        name: "Created",
        selector: (row) => row.created,
        sortable: true,
        grow: 1,
      },
      {
        name: "",
        selector: (row) => {
          return (
            <button
              className="btn-link-style"
              onClick={() => reviewEditClick(row)}
            >
              <FaEdit />
            </button>
          );
        },
      },
    ],
    []
  );

  const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      style: {
        fontSize: "16px",
      },
    },
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="table_outer_div">
            <DataTable
              title="Reviews"
              data={reviewItems}
              columns={columns}
              fixedHeader
              fixedHeaderScrollHeight="100%"
              pagination
              progressPending={pending}
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
      <Modal
        isOpen={openEditModal}
        toggle={() => setOpenEditModal(!openEditModal)}
      >
        <ModalHeader
          className="modal-head"
          toggle={() => setOpenEditModal(!openEditModal)}
        >
          Change Review State
        </ModalHeader>
        <ModalBody>
          <EditReviewState
            reviewObject={clickedReview}
            setOpenEditModal={setOpenEditModal}
            path="/reviews"
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AllReviews;
