import React from "react";

function Root() {
  return (
    <div>
      <h1>Template Generator Version 1.0</h1>
    </div>
  );
}

export default Root;
