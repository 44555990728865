import axios from "axios";

const siteDomain = localStorage.getItem("tg-site-domain");

export const readAllHomePages = async () => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/home/read_all.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const addNewHome = async ({
  title,
  description,
  keywords,
  subTitle,
  buttonTitle,
  buttonUrl,
  headImg,
  sectionOneHead,
  sectionOneSub1Head,
  sectionOneSub1Sub,
  sectionOneSub2Head,
  sectionOneSub2Sub,
  sectionOneSub3Head,
  sectionOneSub3Sub,
  mainArticleId,
  conversationImg,
  conversationOne,
  conversationTwo,
  conversationThree,
  articleOne,
  articleTwo,
  articleThree,
  articleFour,
  addToGoogleSearch,
  disclaimerHeader,
}) => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("title", title);
    formData.append("subTitle", subTitle);
    formData.append("buttonTitle", buttonTitle);
    formData.append("buttonUrl", buttonUrl);
    formData.append("description", description);
    formData.append("keywords", keywords);
    formData.append("headImg", headImg);
    formData.append("sectionOneHead", sectionOneHead);
    formData.append("sectionOneSub1Head", sectionOneSub1Head);
    formData.append("sectionOneSub1Sub", sectionOneSub1Sub);
    formData.append("sectionOneSub2Head", sectionOneSub2Head);
    formData.append("sectionOneSub2Sub", sectionOneSub2Sub);
    formData.append("sectionOneSub3Head", sectionOneSub3Head);
    formData.append("sectionOneSub3Sub", sectionOneSub3Sub);
    formData.append("mainArticleId", mainArticleId);
    formData.append("conversationImg", conversationImg);
    formData.append("conversationOne", conversationOne);
    formData.append("conversationTwo", conversationTwo);
    formData.append("conversationThree", conversationThree);
    formData.append("articleOne", articleOne);
    formData.append("articleTwo", articleTwo);
    formData.append("articleThree", articleThree);
    formData.append("articleFour", articleFour);
    formData.append("addToGoogleSearch", addToGoogleSearch);
    formData.append("disclaimerHeader", disclaimerHeader);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/home/create.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const updateHome = async ({
  homeId,
  title,
  description,
  keywords,
  subTitle,
  buttonTitle,
  buttonUrl,
  headImg,
  sectionOneHead,
  sectionOneSub1Head,
  sectionOneSub1Sub,
  sectionOneSub2Head,
  sectionOneSub2Sub,
  sectionOneSub3Head,
  sectionOneSub3Sub,
  mainArticleId,
  conversationImg,
  conversationOne,
  conversationTwo,
  conversationThree,
  articleOne,
  articleTwo,
  articleThree,
  articleFour,
  addToGoogleSearch,
  disclaimerHeader,
}) => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("homeId", homeId);
    formData.append("title", title);
    formData.append("subTitle", subTitle);
    formData.append("buttonTitle", buttonTitle);
    formData.append("buttonUrl", buttonUrl);
    formData.append("description", description);
    formData.append("keywords", keywords);
    formData.append("headImg", headImg);
    formData.append("sectionOneHead", sectionOneHead);
    formData.append("sectionOneSub1Head", sectionOneSub1Head);
    formData.append("sectionOneSub1Sub", sectionOneSub1Sub);
    formData.append("sectionOneSub2Head", sectionOneSub2Head);
    formData.append("sectionOneSub2Sub", sectionOneSub2Sub);
    formData.append("sectionOneSub3Head", sectionOneSub3Head);
    formData.append("sectionOneSub3Sub", sectionOneSub3Sub);
    formData.append("mainArticleId", mainArticleId);
    formData.append("conversationImg", conversationImg);
    formData.append("conversationOne", conversationOne);
    formData.append("conversationTwo", conversationTwo);
    formData.append("conversationThree", conversationThree);
    formData.append("articleOne", articleOne);
    formData.append("articleTwo", articleTwo);
    formData.append("articleThree", articleThree);
    formData.append("articleFour", articleFour);
    formData.append("addToGoogleSearch", addToGoogleSearch);
    formData.append("disclaimerHeader", disclaimerHeader);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/home/update.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readHomeById = async ({ homeId }) => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("homeId", homeId);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/home/read_by_id.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
