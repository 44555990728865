import React, { useState } from "react";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  useField,
  useFormikContext,
} from "formik";
import * as Yup from "yup";
import { Alert, FormGroup } from "reactstrap";
import FormikUploadImgInput from "../../../../components/Formik/FormikUploadImgInput/FormikUploadImgInput";

import { updateAuthor } from "../../../../api/authorAPI";

import "./EditAuthorForm.css";

const TextArea = ({ label, ...props }) => {
  const [field] = useField(props);
  return (
    <>
      <textarea className="form-control" {...field} {...props} />
    </>
  );
};

const EditAuthorForm = ({ authorItem, setOpenUpdateAuthorModal }) => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);

  function successSubmission() {
    setOpenUpdateAuthorModal(false);
    window.location.href = "/authors";
  }

  function failSubmission(head, sub) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }
  console.log(authorItem);
  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-sm-12">
          <div className="">
            <Formik
              enableReinitialize
              initialValues={{
                firstName: authorItem?.firstName,
                lastName: authorItem?.lastName,
                about: authorItem?.about,
                authorImg: authorItem?.imgPath,
              }}
              validationSchema={Yup.object({
                firstName: Yup.string()
                  .min(2, "FirstName cannot smaller than 2 characters")
                  .max(50, "FirstName cannot exceed 50 characters")
                  .required("Required"),
                lastName: Yup.string()
                  .min(2, "LastName cannot smaller than 2 characters")
                  .max(50, "LastName cannot exceed 50 characters")
                  .required("Required"),
                about: Yup.string()
                  .min(2, "About cannot smaller than 2 characters")
                  .max(250, "About cannot exceed 250 characters")
                  .required("Required"),
                authorImg: Yup.mixed().required("Img File is required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                setIsFailSubmission(false);

                updateAuthor({
                  authorId: authorItem?.authorId,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  about: values.about,
                  authorImg: values.authorImg,
                }).then((data) => {
                  try {
                    if (data["result"]) {
                      if (data["result"] === "SUCCESS") {
                        successSubmission(data["data"]);
                      } else if (data["result"] === "FAILED") {
                        failSubmission("¡Error!", data["data"]["errorMessage"]);
                      } else {
                        failSubmission("Error!", "Please try again later!");
                      }
                    } else {
                      failSubmission("Error!", "Please try again later!");
                    }
                  } catch (error) {
                    failSubmission("Error!", "Please try again later!");
                  }
                });
              }}
            >
              <Form>
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-8 mt-4 mb-4">
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="firstName"
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="firstName">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="lastName"
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="lastName">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <TextArea
                          name="about"
                          rows="6"
                          placeholder="Enter description about author"
                        />
                        <ErrorMessage name="about">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <p>
                          <b>Upload Author Image</b>
                        </p>
                        <FormikUploadImgInput
                          name="authorImg"
                          imgSrc={
                            authorItem?.imgPath
                              ? `${localStorage.getItem(
                                  "tg-site-domain"
                                )}/content/uploads/img/${authorItem?.imgPath}`
                              : ""
                          }
                        />
                        <ErrorMessage name="authorImg">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                    {isFailSubmission && (
                      <div className="container">
                        <div className="row">
                          <div className="col text-center">
                            <>
                              <Alert color="danger">
                                <p className="mb-0">{errorMsg.sub}</p>
                              </Alert>
                            </>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12 text-center">
                          <button
                            type="submit"
                            className="button-submit-style"
                            disabled={isLoading}
                          >
                            Update Author
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAuthorForm;
