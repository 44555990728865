import { useEffect, useState } from "react";

import { readSiteData } from "../../../api/siteAPI";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import SiteEditForm from "../../site/forms/siteEditForm/SiteEditForm";

function Settings() {
  const [siteData, setSiteData] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    readSiteData().then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setSiteData(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [setSiteData.length]);

  return (
    <>
      {!pending && (
        <div className="container">
          <div className="row mb-3">
            <div className="col-sm-12">
              <h2>Site Settings</h2>
            </div>
          </div>
          <SiteEditForm siteData={siteData} />
        </div>
      )}
      {pending && <LoadingScreen />}
    </>
  );
}

export default Settings;
