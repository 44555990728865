import React, { useState } from "react";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  useField,
  useFormikContext,
} from "formik";
import * as Yup from "yup";
import { Alert, FormGroup } from "reactstrap";

import ArticlePageList from "../../../../components/ArticlePageList/ArticlePageList";
import ReviewList from "../../../../components/ReviewList/ReviewList";
import FormikUploadImgInput from "../../../../components/Formik/FormikUploadImgInput/FormikUploadImgInput";

import "./AddHomeForm.css";
import { addNewHome } from "../../../../api/homeAPI";

const TextArea = ({ label, ...props }) => {
  const [field] = useField(props);
  return (
    <>
      <textarea className="form-control" {...field} {...props} />
    </>
  );
};

const ArticleSelectDropdown = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <ArticlePageList
      changeCurrentValue={(val) => {
        setFieldValue(field.name, val.code);
      }}
      selectedOption={field.value}
    />
  );
};

const ReviewSelectDropdown = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <ReviewList
      changeCurrentValue={(val) => {
        setFieldValue(field.name, val.code);
      }}
      selectedOption={field.value}
    />
  );
};

const AddHomeForm = () => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);

  function successSubmission() {
    window.location.href = "/home";
  }

  function failSubmission(head, sub) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="">
            <Formik
              initialValues={{
                title: "",
                description: "",
                keywords: "",
                subTitle: "",
                buttonTitle: "",
                buttonUrl: "",
                headImg: "",
                sectionOneHead: "",
                sectionOneSub1Head: "",
                sectionOneSub1Sub: "",
                sectionOneSub2Head: "",
                sectionOneSub2Sub: "",
                sectionOneSub3Head: "",
                sectionOneSub3Sub: "",
                mainArticleId: "",
                conversationImg: "",
                conversationOne: "",
                conversationTwo: "",
                conversationThree: "",
                articleOne: "",
                articleTwo: "",
                articleThree: "",
                articleFour: "",
                addToGoogleSearch: "ADD",
                disclaimerHeader: "",
              }}
              validationSchema={Yup.object({
                title: Yup.string()
                  .min(10, "Title cannot smaller than 10 characters")
                  .max(70, "Title cannot exceed 70 characters")
                  .required("Required"),
                description: Yup.string()
                  .min(10, "description cannot smaller than 10 characters")
                  .max(150, "description cannot exceed 150 characters")
                  .required("Required"),
                keywords: Yup.string()
                  .min(10, "keywords cannot smaller than 10 characters")
                  .max(70, "keywords cannot exceed 70 characters")
                  .required("Required"),
                subTitle: Yup.string()
                  .min(2, "Sub Title cannot smaller than 2 characters")
                  .max(50, "Sub Title cannot exceed 50 characters")
                  .required("Required"),
                buttonTitle: Yup.string()
                  .min(2, "Button Title cannot smaller than 2 characters")
                  .max(50, "Button Title cannot exceed 50 characters")
                  .required("Required"),
                buttonUrl: Yup.string()
                  .matches(
                    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                    "Enter correct url!"
                  )
                  .min(2, "Button Url cannot smaller than 2 characters")
                  .max(100, "Button Url cannot exceed 100 characters")
                  .required("Required"),
                headImg: Yup.mixed().required("Img File is required"),
                sectionOneHead: Yup.string()
                  .min(2, "Info Head cannot smaller than 2 characters")
                  .max(50, "Info Head cannot exceed 50 characters")
                  .required("Required"),
                sectionOneSub1Head: Yup.string()
                  .min(2, "Info One Head cannot smaller than 2 characters")
                  .max(50, "Info One Head cannot exceed 50 characters")
                  .required("Required"),
                sectionOneSub1Sub: Yup.string()
                  .min(2, "Info One Sub Text cannot smaller than 2 characters")
                  .max(100, "Info One Sub Text exceed 100 characters")
                  .required("Required"),
                sectionOneSub2Head: Yup.string()
                  .min(2, "Info Two Head cannot smaller than 2 characters")
                  .max(50, "Info Two Head cannot exceed 50 characters")
                  .required("Required"),
                sectionOneSub2Sub: Yup.string()
                  .min(2, "Info Two Sub Text cannot smaller than 2 characters")
                  .max(100, "Info Two Sub Text exceed 100 characters")
                  .required("Required"),
                sectionOneSub3Head: Yup.string()
                  .min(2, "Info Three Head cannot smaller than 2 characters")
                  .max(50, "Info Three Head cannot exceed 50 characters")
                  .required("Required"),
                sectionOneSub3Sub: Yup.string()
                  .min(
                    2,
                    "Info Three Sub Text cannot smaller than 2 characters"
                  )
                  .max(100, "Info Three Sub Text exceed 100 characters")
                  .required("Required"),
                mainArticleId: Yup.string().required("Required"),
                conversationImg: Yup.mixed().required("Img File is required"),
                conversationOne: Yup.string().required("Required"),
                conversationTwo: Yup.string().required("Required"),
                conversationThree: Yup.string().required("Required"),
                articleOne: Yup.string().required("Required"),
                articleTwo: Yup.string().required("Required"),
                articleThree: Yup.string().required("Required"),
                articleFour: Yup.string().required("Required"),
                addToGoogleSearch: Yup.string().required("Required"),
                disclaimerHeader: Yup.string().max(
                  20,
                  "Disclaimer header cannot exceed 20 characters"
                ),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                setIsFailSubmission(false);

                addNewHome({
                  title: values.title,
                  description: values.description,
                  keywords: values.keywords,
                  subTitle: values.subTitle,
                  buttonTitle: values.buttonTitle,
                  buttonUrl: values.buttonUrl,
                  headImg: values.headImg,
                  sectionOneHead: values.sectionOneHead,
                  sectionOneSub1Head: values.sectionOneSub1Head,
                  sectionOneSub1Sub: values.sectionOneSub1Sub,
                  sectionOneSub2Head: values.sectionOneSub2Head,
                  sectionOneSub2Sub: values.sectionOneSub2Sub,
                  sectionOneSub3Head: values.sectionOneSub3Head,
                  sectionOneSub3Sub: values.sectionOneSub3Sub,
                  mainArticleId: values.mainArticleId,
                  conversationImg: values.conversationImg,
                  conversationOne: values.conversationOne,
                  conversationTwo: values.conversationTwo,
                  conversationThree: values.conversationThree,
                  articleOne: values.articleOne,
                  articleTwo: values.articleTwo,
                  articleThree: values.articleThree,
                  articleFour: values.articleFour,
                  addToGoogleSearch: values.addToGoogleSearch,
                  disclaimerHeader: values.disclaimerHeader,
                }).then((data) => {
                  try {
                    if (data["result"]) {
                      if (data["result"] === "SUCCESS") {
                        successSubmission(data["data"]);
                      } else if (data["result"] === "FAILED") {
                        failSubmission("¡Error!", data["data"]["errorMessage"]);
                      } else {
                        failSubmission("Error!", "Please try again later!");
                      }
                    } else {
                      failSubmission("Error!", "Please try again later!");
                    }
                  } catch (error) {
                    failSubmission("Error!", "Please try again later!");
                  }
                });
              }}
            >
              <Form>
                <div className="row">
                  <div className="col-sm-8 mb-5">
                    <div className="home-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="form-head-txt">Home Page Details</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Title</label>
                          <FormGroup className="input-payment-box">
                            <Field
                              name="title"
                              type="text"
                              className="form-control"
                              placeholder="Title"
                              disabled={isLoading}
                            />
                            <ErrorMessage name="title">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Description</label>
                          <TextArea
                            name="description"
                            rows="3"
                            placeholder="Description (Meta Tag)"
                          />
                          <ErrorMessage name="description">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">
                            Keywords (Meta Tag, Use Comma to separate keywords)
                          </label>
                          <FormGroup className="input-payment-box">
                            <Field
                              name="keywords"
                              type="text"
                              className="form-control"
                              placeholder="Keywords (Meta Tag)"
                              disabled={isLoading}
                            />
                            <ErrorMessage name="keywords">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Sub Title</label>
                          <FormGroup className="input-payment-box">
                            <Field
                              name="subTitle"
                              type="text"
                              className="form-control"
                              placeholder="Sub Title"
                              disabled={isLoading}
                            />
                            <ErrorMessage name="subTitle">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Button Title</label>
                          <FormGroup className="input-payment-box">
                            <Field
                              name="buttonTitle"
                              type="text"
                              className="form-control"
                              placeholder="Button Title"
                              disabled={isLoading}
                            />
                            <ErrorMessage name="buttonTitle">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">
                            Button Url (Add with Https)
                          </label>
                          <FormGroup className="input-payment-box">
                            <Field
                              name="buttonUrl"
                              type="text"
                              className="form-control"
                              placeholder="Button Url"
                              disabled={isLoading}
                            />
                            <ErrorMessage name="buttonUrl">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="home-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="form-head-txt">Introduction Section</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Header Text</label>
                          <FormGroup className="input-payment-box">
                            <Field
                              name="sectionOneHead"
                              type="text"
                              className="form-control"
                              placeholder="Header Text"
                              disabled={isLoading}
                            />
                            <ErrorMessage name="sectionOneHead">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">
                            Section One Head
                          </label>
                          <FormGroup className="input-payment-box">
                            <Field
                              name="sectionOneSub1Head"
                              type="text"
                              className="form-control"
                              placeholder="Section One Head"
                              disabled={isLoading}
                            />
                            <ErrorMessage name="sectionOneSub1Head">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">
                            Section One Sub Text
                          </label>
                          <TextArea
                            name="sectionOneSub1Sub"
                            rows="3"
                            placeholder="Section One Sub Text"
                          />
                          <ErrorMessage name="sectionOneSub1Sub">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">
                            Section Two Head
                          </label>
                          <FormGroup className="input-payment-box">
                            <Field
                              name="sectionOneSub2Head"
                              type="text"
                              className="form-control"
                              placeholder="Section Two Head"
                              disabled={isLoading}
                            />
                            <ErrorMessage name="sectionOneSub2Head">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">
                            Section Two Sub Text
                          </label>
                          <TextArea
                            name="sectionOneSub2Sub"
                            rows="3"
                            placeholder="Section Two Sub Text"
                          />
                          <ErrorMessage name="sectionOneSub2Sub">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">
                            Section Three Head
                          </label>
                          <FormGroup className="input-payment-box">
                            <Field
                              name="sectionOneSub3Head"
                              type="text"
                              className="form-control"
                              placeholder="Section Three Head"
                              disabled={isLoading}
                            />
                            <ErrorMessage name="sectionOneSub3Head">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">
                            Section Three Sub Text
                          </label>
                          <TextArea
                            name="sectionOneSub3Sub"
                            rows="3"
                            placeholder="Section Three Sub Text"
                          />
                          <ErrorMessage name="sectionOneSub3Sub">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="home-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12 mb-3">
                          <p>
                            <b>Upload Hero image</b>
                          </p>
                          <FormikUploadImgInput name="headImg" imgSrc="" />
                          <ErrorMessage name="headImg">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                    </div>
                    <div className="home-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Main Article</label>
                          <FormGroup className="input-payment-box">
                            <ArticleSelectDropdown
                              className="form-control"
                              name="mainArticleId"
                            />
                            <ErrorMessage name="mainArticleId">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">
                            Sub Article One
                          </label>
                          <FormGroup className="input-payment-box">
                            <ArticleSelectDropdown
                              className="form-control"
                              name="articleOne"
                            />
                            <ErrorMessage name="articleOne">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">
                            Sub Article Two
                          </label>
                          <FormGroup className="input-payment-box">
                            <ArticleSelectDropdown
                              className="form-control"
                              name="articleTwo"
                            />
                            <ErrorMessage name="articleTwo">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">
                            Sub Article Three
                          </label>
                          <FormGroup className="input-payment-box">
                            <ArticleSelectDropdown
                              className="form-control"
                              name="articleThree"
                            />
                            <ErrorMessage name="articleThree">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">
                            Sub Article Four
                          </label>
                          <FormGroup className="input-payment-box">
                            <ArticleSelectDropdown
                              className="form-control"
                              name="articleFour"
                            />
                            <ErrorMessage name="articleFour">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="home-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12 mb-3">
                          <p>
                            <b>Upload Conversation image</b>
                          </p>
                          <FormikUploadImgInput
                            name="conversationImg"
                            imgSrc=""
                          />
                          <ErrorMessage name="conversationImg">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">
                            Conversation One
                          </label>
                          <FormGroup className="input-payment-box">
                            <ReviewSelectDropdown
                              className="form-control"
                              name="conversationOne"
                            />
                            <ErrorMessage name="conversationOne">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">
                            Conversation Two
                          </label>
                          <FormGroup className="input-payment-box">
                            <ReviewSelectDropdown
                              className="form-control"
                              name="conversationTwo"
                            />
                            <ErrorMessage name="conversationTwo">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">
                            Conversation Three
                          </label>
                          <FormGroup className="input-payment-box">
                            <ReviewSelectDropdown
                              className="form-control"
                              name="conversationThree"
                            />
                            <ErrorMessage name="conversationThree">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="home-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">
                            Disclaimer Header
                          </label>
                          <FormGroup className="input-payment-box">
                            <Field
                              name="disclaimerHeader"
                              type="text"
                              className="form-control"
                              placeholder="Disclaimer Header"
                              disabled={isLoading}
                            />
                            <ErrorMessage name="disclaimerHeader">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">
                            Add to Google Search
                          </label>
                          <FormGroup className="input-payment-box">
                            <Field
                              name="addToGoogleSearch"
                              as="select"
                              className="form-control"
                              placeholder="Select"
                              disabled={isLoading}
                            >
                              <option value="ADD">Add</option>
                              <option value="DONT">Don't Add</option>
                            </Field>
                            <ErrorMessage name="addToGoogleSearch">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="home-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="form-head-txt">Publish</p>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="col-sm-8 mt-4 mb-4">
                          {isFailSubmission && (
                            <div className="container">
                              <div className="row">
                                <div className="col text-center">
                                  <>
                                    <Alert color="danger">
                                      <p className="mb-0">{errorMsg.sub}</p>
                                    </Alert>
                                  </>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="container">
                            <div className="row d-flex justify-content-center">
                              <div className="col-sm-12 text-center">
                                <button
                                  type="submit"
                                  className="button-submit-style"
                                  disabled={isLoading}
                                >
                                  Add Home Page
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddHomeForm;
