import AddHomeForm from "../../forms/addHomeForm/AddHomeForm";

function AddHome() {
  return (
    <>
      <div className="container">
        <div className="row mt-3 mb-3">
          <div className="col-sm-12">
            <h2>Home Page</h2>
          </div>
        </div>
        <AddHomeForm />
      </div>
    </>
  );
}

export default AddHome;
