import React, { useState } from "react";

function ColorPickerBox({ selectedOption, changeCurrentValue }) {
  const [selectedValue, setSelectedValue] = useState(selectedOption);

  const handleChange = (value) => {
    if (value !== null) {
      setSelectedValue(value);
      changeCurrentValue(value);
      console.log(value);
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <input
          type="color"
          value={selectedValue}
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
    </div>
  );
}

export default ColorPickerBox;
