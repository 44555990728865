import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "semantic-ui-react";

import TextIcon from "../TextIcon";

import "./SideMenu.css";

const adminRoutesWithRootPage = [
  {
    title: "Home",
    route: "/home",
    icon: "home",
  },
  {
    title: "Article",
    route: "/articles",
    icon: "newspaper",
  },
  {
    title: "Product Review",
    route: "/product-review-pages",
    icon: "newspaper outline",
  },
  {
    title: "Authors",
    route: "/authors",
    icon: "user outline",
  },
  {
    title: "Snippets",
    route: "/snippets",
    icon: "table",
  },
  {
    title: "Review",
    route: "/reviews",
    icon: "comments",
  },
  {
    title: "Message",
    route: "/message",
    icon: "comment alternate",
  },
  {
    title: "Users",
    route: "/users",
    icon: "user",
  },
  {
    title: "Sites",
    route: "/sites",
    icon: "sitemap",
  },
  {
    title: "Settings",
    route: "/settings",
    icon: "cog",
  },
];

const adminRoutesWithSubPage = [
  {
    title: "Home",
    route: "/home",
    icon: "home",
  },
  {
    title: "Article",
    route: "/articles",
    icon: "newspaper",
  },
  {
    title: "Product Review",
    route: "/product-review-pages",
    icon: "newspaper outline",
  },
  {
    title: "Authors",
    route: "/authors",
    icon: "user outline",
  },
  {
    title: "Snippets",
    route: "/snippets",
    icon: "table",
  },
  {
    title: "Review",
    route: "/reviews",
    icon: "comments",
  },
  {
    title: "Message",
    route: "/message",
    icon: "comment alternate",
  },
  {
    title: "Settings",
    route: "/settings",
    icon: "cog",
  },
];

const SideMenu = ({ smallMenu }) => {
  const [activeItem, setActiveItem] = useState(
    adminRoutesWithRootPage[0].title
  );

  const handleItemClick = (value) => {
    setActiveItem(value);
  };

  const handleLogout = () => {
    localStorage.removeItem("tg-auth-firstName");
    localStorage.removeItem("tg-auth-lastName");
    localStorage.removeItem("tg-auth-email");
    localStorage.removeItem("tg-auth-role");
    localStorage.removeItem("tg-auth-token");
    localStorage.removeItem("tg-is-valid-auth");
    localStorage.removeItem("tg-site-domain");

    window.location.href = "/login";
  };

  const getUserRoutes = () => {
    if (
      localStorage.getItem("tg-auth-role") === "ADMIN" &&
      localStorage.getItem("tg-site-domain") === process.env.REACT_APP_API_HOST
    ) {
      return adminRoutesWithRootPage;
    }
    return adminRoutesWithSubPage;
  };

  return (
    <Menu
      fixed="left"
      borderless
      className={(smallMenu ? "small-side" : "") + " side"}
      vertical
    >
      {getUserRoutes().map(({ title, icon, route }) => (
        <Menu.Item
          as={Link}
          to={route}
          name={icon}
          active={activeItem === title}
          onClick={() => handleItemClick(title)}
          key={title}
        >
          <TextIcon
            hideText={smallMenu}
            color={activeItem === title ? "blue" : "black"}
            name={icon}
          >
            {title}
          </TextIcon>
        </Menu.Item>
      ))}
      <Menu.Item onClick={handleLogout} className="logout-btn" name={"user"}>
        <TextIcon hideText={smallMenu} color="black" name="angle double left">
          Log Out
        </TextIcon>
      </Menu.Item>
    </Menu>
  );
};

export default SideMenu;
