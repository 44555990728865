import axios from "axios";

const siteDomain = localStorage.getItem("tg-site-domain");

export const readAllAuthors = async () => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/author/read_all.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const addNewAuthor = async ({
  firstName,
  lastName,
  about,
  authorImg,
}) => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("about", about);
    formData.append("authorImg", authorImg);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/author/create.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const getUsedAuthorList = async (word) => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("q", word);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/author/read_list.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readByAuthorId = async ({ authorId }) => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("authorId", authorId);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/author/read_by_id.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const updateAuthor = async ({
  authorId,
  firstName,
  lastName,
  about,
  authorImg,
}) => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("authorId", authorId);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("about", about);
    formData.append("authorImg", authorImg);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/author/update.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
