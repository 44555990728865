import React, { useState } from "react";
import Slider from "react-rangeslider";

import "react-rangeslider/lib/index.css";

function RangeBarInput({ selectedOption, changeCurrentValue }) {
  const [selectedValue, setSelectedValue] = useState(selectedOption);

  const handleChange = (value) => {
    if (value !== null) {
      setSelectedValue(value);
      changeCurrentValue(value);
    }
  };

  return (
    <div className="row">
      <div className="col-10">
        <div className="slider">
          <Slider
            min={0}
            max={100}
            value={selectedValue}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-2">
        <div
          className="value"
          style={{ margin: "15px" }}
        >{`${selectedValue} %`}</div>
      </div>
    </div>
  );
}

export default RangeBarInput;
