import { useMemo } from "react";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiFillPlusCircle } from "react-icons/ai";

import { FaEdit } from "react-icons/fa";
import { Image } from "semantic-ui-react";
import { readAllSites } from "../../../../api/siteAPI";

import "./AllSites.css";

const AllSites = () => {
  const [siteItems, setSiteItems] = useState([]);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    readAllSites().then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setSiteItems(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [siteItems.length]);

  const columns = useMemo(
    () => [
      {
        name: "",
        selector: (row) => {
          return (
            <>
              <Image
                src={`${localStorage.getItem(
                  "tg-site-domain"
                )}/content/uploads/img/${row.favicon}`}
                avatar
              />
            </>
          );
        },
        grow: 1,
      },
      {
        name: "Name",
        selector: (row) => row.siteName,
        sortable: true,
        grow: 2,
      },
      {
        name: "Domain",
        selector: (row) => row.domain,
        sortable: true,
        grow: 2,
      },
      {
        name: "Site Id",
        selector: (row) => row.siteId,
        sortable: true,
        grow: 2,
      },
      //   {
      //     name: "",
      //     selector: (row) => {
      //       return (
      //         <>
      //           <p
      //             className="btn-link-style home-icon"
      //             onClick={() => {
      //               //   onClickAuthor(row);
      //             }}
      //           >
      //             <FaEdit />
      //           </p>
      //         </>
      //       );
      //     },
      //   },
    ],
    []
  );

  const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      style: {
        fontSize: "16px",
      },
    },
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <button
            type="button"
            className="button-add-style"
            onClick={() => {
              window.location.href = "/add-site";
            }}
          >
            <AiFillPlusCircle /> Add a Site
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="table_outer_div">
            <DataTable
              title="Sites"
              data={siteItems}
              columns={columns}
              fixedHeader
              fixedHeaderScrollHeight="100%"
              pagination
              progressPending={pending}
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllSites;
