import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { readHomeById } from "../../../../api/homeAPI";
import EditHomeForm from "../../forms/editHomeForm/EditHomeForm";

function EditHomePage() {
  const { homeId } = useParams();
  const [homeItem, setHomeItem] = useState(null);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    readHomeById({ homeId }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setHomeItem(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [homeItem?.homeId, homeId]);

  return (
    <>
      {!pending && (
        <div className="container">
          <div className="row mt-3 mb-3">
            <div className="col-sm-12">
              <h2>{homeItem?.title}</h2>
            </div>
          </div>
          {homeItem && <EditHomeForm homeItem={homeItem} />}
        </div>
      )}
    </>
  );
}

export default EditHomePage;
