import AddProductReviewForm from "../../forms/addProductReviewForm/AddProductReviewForm";

function AddProductReviewPage() {
  return (
    <>
      <div className="container">
        <div className="row mt-3 mb-3">
          <div className="col-sm-12">
            <h2>Add Product Review Page</h2>
          </div>
        </div>
        <AddProductReviewForm />
      </div>
    </>
  );
}

export default AddProductReviewPage;
