import AddArticleForm from "../../forms/addArticleForm/AddArticleForm";

function AddArticle() {
  return (
    <>
      <div className="container">
        <div className="row mt-3 mb-3">
          <div className="col-sm-12">
            <h2>Add Article</h2>
          </div>
        </div>
        <AddArticleForm />
      </div>
    </>
  );
}

export default AddArticle;
