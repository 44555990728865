import { useEffect, useState } from "react";

import { readSiteData } from "../../../../api/siteAPI";
import LoadingScreen from "../../../../components/LoadingScreen/LoadingScreen";
import SiteAddForm from "../../forms/siteAddForm/SiteAddForm";

function AddSite() {
  return (
    <>
      <div className="container">
        <div className="row mb-3">
          <div className="col-sm-12">
            <h2>Add new Site</h2>
          </div>
        </div>
        <SiteAddForm />
      </div>
    </>
  );
}

export default AddSite;
