import { useMemo } from "react";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiOutlineUserAdd } from "react-icons/ai";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { FaEdit } from "react-icons/fa";

import { readAllSnippets } from "../../../../api/snippetAPI";
import renderSnippetType from "../../../../meta/other";

import "./AllSnippetsPage.css";

const AllSnippetsPage = () => {
  const [snippetItems, setSnippetItems] = useState([]);
  const [pending, setPending] = useState(true);
  const [openSelectTypeModal, setOpenSelectTypeModal] = useState(false);

  useEffect(() => {
    readAllSnippets().then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setSnippetItems(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [snippetItems.length]);

  const columns = useMemo(
    () => [
      {
        name: "Name",
        selector: (row) => row.name,
        sortable: true,
        // grow: 4,
      },
      {
        name: "Type",
        selector: (row) => {
          return renderSnippetType(row.type);
        },
        sortable: true,
        // grow: 4,
      },
      {
        name: "Created",
        selector: (row) => row.created,
        sortable: true,
      },
      {
        name: "",
        selector: (row) => {
          return (
            <>
              {row.type === "TYPE_1" && (
                <a
                  className="btn-link-style home-icon"
                  href={`/edit-normal-snippet/${row.snippetId}`}
                >
                  <FaEdit />
                </a>
              )}
              {row.type === "CUSTOM" && (
                <a
                  className="btn-link-style home-icon"
                  href={`/edit-custom-snippet/${row.snippetId}`}
                >
                  <FaEdit />
                </a>
              )}
            </>
          );
        },
      },
    ],
    []
  );

  const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      style: {
        fontSize: "16px",
      },
    },
  };

  function snippetTypeChange(e) {
    if (e.target.value === "CUSTOM") {
      window.location.href = "/add-custom-snippet";
    } else if (e.target.value === "TYPE_1") {
      window.location.href = "/add-normal-snippet";
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <button
            type="button"
            className="button-add-style"
            onClick={() => {
              setOpenSelectTypeModal(true);
            }}
          >
            <AiOutlineUserAdd /> Add a Snippet
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="table_outer_div">
            <DataTable
              title="Snippets"
              data={snippetItems}
              columns={columns}
              fixedHeader
              fixedHeaderScrollHeight="100%"
              pagination
              progressPending={pending}
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
      <Modal
        isOpen={openSelectTypeModal}
        toggle={() => setOpenSelectTypeModal(!openSelectTypeModal)}
      >
        <ModalHeader
          className="modal-head"
          toggle={() => setOpenSelectTypeModal(!openSelectTypeModal)}
        >
          Select Snippet Type
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-sm-12 mb-3">
              <select
                as="select"
                className="form-control"
                onChange={(e) => {
                  snippetTypeChange(e);
                }}
                defaultValue=""
              >
                <option value="" disabled>
                  Select Snippet Type
                </option>
                <option value="TYPE_1">Normal</option>
                <option value="CUSTOM">Custom</option>
              </select>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AllSnippetsPage;
