import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage, useField, useFormikContext } from "formik";
import * as Yup from "yup";
import { Alert, FormGroup } from "reactstrap";

import SnippetList from "../../../../components/SnippetList/SnippetList";
import AuthorList from "../../../../components/AuthorList/AuthorList";
import HtmlEditor from "../../../../components/HtmlEditor/HtmlEditor";
import RangeBarInput from "../../../../components/RangeBarInput/RangeBarInput";
import FormikUploadImgInput from "../../../../components/Formik/FormikUploadImgInput/FormikUploadImgInput";
import ProductPageList from "../../../../components/ProductPageList/ProductPageList";

import "./EditProductReviewForm.css";
import { updateProductReview } from "../../../../api/productReviewAPI";

const TextArea = ({ label, ...props }) => {
  const [field] = useField(props);
  return (
    <>
      <textarea className="form-control" {...field} {...props} />
    </>
  );
};

const ProductSelectDropdown = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <ProductPageList
      changeCurrentValue={(val) => {
        setFieldValue(field.name, val);
      }}
      selectedOption={field.value}
    />
  );
};

const SnippetSelectDropdown = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  return (
    <SnippetList
      changeCurrentValue={(val) => {
        setFieldValue(field.name, val);
      }}
      selectedOption={field.value}
    />
  );
};

const AuthorSelectDropdown = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <AuthorList
      changeCurrentValue={(val) => {
        setFieldValue(field.name, val);
      }}
      selectedOption={field.value}
    />
  );
};

const RangeBarBox = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <RangeBarInput
      changeCurrentValue={(val) => {
        setFieldValue(field.name, val);
      }}
      selectedOption={field.value}
    />
  );
};

const EditProductReviewForm = ({ productReviewItem }) => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [ingredientsBody, setIngredientsBody] = useState(productReviewItem?.ingredientsBody);
  const [extraBody, setExtraBody] = useState(productReviewItem?.extraBody);
  const [snippetType, setSnippetType] = useState(productReviewItem?.snippet?.type);
  const [isSuccessSubmission, setIsSuccessSubmission] = useState(false);

  function failSubmission(head, sub) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  function ingredientsBodyChange(value) {
    setIngredientsBody(value);
  }

  function extraBodyChange(value) {
    setExtraBody(value);
  }

  function changeSnippetType(e) {
    setSnippetType(e.target.value);
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="">
            <Formik
              enableReinitialize
              initialValues={{
                title: productReviewItem?.title,
                productName: productReviewItem?.productName,
                slug: productReviewItem?.slug,
                quote: productReviewItem?.quote,
                summary: productReviewItem?.summary,
                keywords: productReviewItem?.keywords,
                productImg: productReviewItem?.productImg,
                addToGoogleSearch: productReviewItem?.addToGoogleSearch,
                offerUrl: productReviewItem?.offerUrl,
                snippetType: productReviewItem?.snippet?.type,
                author: {
                  label: productReviewItem?.author?.name,
                  value: productReviewItem?.author?.name,
                  code: productReviewItem?.author?.authorId,
                },
                snippet: {
                  label: productReviewItem?.snippet?.name,
                  value: productReviewItem?.snippet?.name,
                  code: productReviewItem?.snippet?.snippetId,
                },
                product: {
                  label: productReviewItem?.product?.title,
                  value: productReviewItem?.product?.title,
                  code: productReviewItem?.product?.productReviewId,
                },
                pros: productReviewItem?.pros,
                cons: productReviewItem?.cons,
                ingredientsBody: productReviewItem?.ingredientsBody,
                howToUseBody: productReviewItem?.howToUseBody,
                comparePopularProductsText: productReviewItem?.comparePopularProductsText,
                comparePopularProductsImg: productReviewItem?.comparePopularProductsImg,
                extraBody: productReviewItem?.extraBody,
                overallRating: productReviewItem?.overallRating,
                effectiveness: productReviewItem?.effectiveness,
                speedResults: productReviewItem?.speedResults,
                ingredientQuality: productReviewItem?.ingredientQuality,
                longTermResults: productReviewItem?.longTermResults,
                s1_rating: productReviewItem?.s1_rating,
                s2_rating: productReviewItem?.s2_rating,
                s3_rating: productReviewItem?.s3_rating,
                s4_rating: productReviewItem?.s4_rating,
                s5_rating: productReviewItem?.s5_rating,
                category: productReviewItem?.category,
                disclaimerHeader: productReviewItem?.disclaimerHeader,
              }}
              validationSchema={Yup.object({
                title: Yup.string()
                  .min(2, "Title cannot smaller than 2 characters")
                  .max(130, "Title cannot exceed 130 characters")
                  .required("Required"),
                productName: Yup.string()
                  .min(2, "Product Name cannot smaller than 2 characters")
                  .max(50, "Product Name cannot exceed 50 characters")
                  .required("Required"),
                slug: Yup.string().min(2, "Slug cannot smaller than 2 characters").max(200, "Slug cannot exceed 200 characters"),
                quote: Yup.string()
                  .min(10, "Quote cannot smaller than 10 characters")
                  .max(50, "Quote cannot exceed 50 characters")
                  .required("Required"),
                summary: Yup.string()
                  .min(10, "Summary cannot smaller than 10 characters")
                  .max(500, "Summary cannot exceed 500 characters")
                  .required("Required"),
                offerUrl: Yup.string()
                  .matches(
                    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                    "Enter correct url!"
                  )
                  .min(10, "Offer Url cannot smaller than 10 characters")
                  .max(250, "Offer Url cannot exceed 250 characters")
                  .required("Required"),
                keywords: Yup.string()
                  .min(2, "Keywords cannot smaller than 2 characters")
                  .max(100, "Keywords cannot exceed 100 characters")
                  .required("Required"),
                productImg: Yup.mixed().required("Img File is required"),
                snippetType: Yup.string().required("Required"),
                // snippet: Yup.string().when("snippetType", {
                //   is: "TYPE_1",
                //   then: Yup.string().required("Required"),
                // }),
                // product: Yup.string().when("snippetType", {
                //   is: "TYPE_2",
                //   then: Yup.string().required("Required"),
                // }),
                addToGoogleSearch: Yup.string().required("Required"),
                pros: Yup.string()
                  .min(2, "Pros cannot smaller than 2 characters")
                  .max(1000, "Pros cannot exceed 1000 characters")
                  .required("Required"),
                cons: Yup.string()
                  .min(2, "Cons cannot smaller than 2 characters")
                  .max(1000, "Cons cannot exceed 1000 characters")
                  .required("Required"),
                overallRating: Yup.number().required("Required"),
                effectiveness: Yup.number().required("Required"),
                speedResults: Yup.number().required("Required"),
                ingredientQuality: Yup.number().required("Required"),
                longTermResults: Yup.number().required("Required"),
                s1_rating: Yup.number().required("Required"),
                s2_rating: Yup.number().required("Required"),
                s3_rating: Yup.number().required("Required"),
                s4_rating: Yup.number().required("Required"),
                s5_rating: Yup.number().required("Required"),
                category: Yup.string().required("Required"),
                disclaimerHeader: Yup.string().max(20, "Disclaimer header cannot exceed 20 characters"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                setIsFailSubmission(false);
                setIsSuccessSubmission(false);

                updateProductReview({
                  productReviewId: productReviewItem?.productReviewId,
                  summary: values.summary,
                  quote: values.quote,
                  keywords: values.keywords,
                  productImg: values.productImg,
                  addToGoogleSearch: values.addToGoogleSearch,
                  authorId: values?.author?.code,
                  offerUrl: values.offerUrl,
                  snippetType: values.snippetType,
                  snippetId: values?.snippet?.code,
                  productId: values?.product?.code,
                  pros: values.pros,
                  cons: values.cons,
                  ingredientsBody: ingredientsBody,
                  howToUseBody: values.howToUseBody,
                  comparePopularProductsText: values.comparePopularProductsText,
                  comparePopularProductsImg: values.comparePopularProductsImg,
                  extraBody: extraBody,
                  overallRating: values.overallRating,
                  effectiveness: values.effectiveness,
                  speedResults: values.speedResults,
                  ingredientQuality: values.ingredientQuality,
                  longTermResults: values.longTermResults,
                  s1_rating: values.s1_rating,
                  s2_rating: values.s2_rating,
                  s3_rating: values.s3_rating,
                  s4_rating: values.s4_rating,
                  s5_rating: values.s5_rating,
                  category: values.category,
                  disclaimerHeader: values.disclaimerHeader,
                }).then((data) => {
                  try {
                    if (data["result"]) {
                      if (data["result"] === "SUCCESS") {
                        setIsSuccessSubmission(true);
                        setIsLoading(false);
                      } else if (data["result"] === "FAILED") {
                        failSubmission("¡Error!", data["data"]["errorMessage"]);
                      } else {
                        failSubmission("Error!", "Please try again later!");
                      }
                    } else {
                      failSubmission("Error!", "Please try again later!");
                    }
                  } catch (error) {
                    failSubmission("Error!", "Please try again later!");
                  }
                });
              }}
            >
              <Form>
                <div className="row">
                  <div className="col-sm-8 mb-5">
                    <div className="product-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="form-head-txt">Product Details</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Title</label>
                          <FormGroup>
                            <Field name="title" type="text" className="form-control" placeholder="Title" disabled={true} />
                            <ErrorMessage name="title">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Product Name</label>
                          <FormGroup>
                            <Field name="productName" type="text" className="form-control" placeholder="Product Name" disabled={true} />
                            <ErrorMessage name="productName">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Slug (If you leave it blank, it will be generated automatically.)</label>
                          <FormGroup className="input-payment-box">
                            <Field name="slug" type="text" className="form-control" placeholder="Slug" disabled={true} />
                            <ErrorMessage name="slug">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Quote</label>
                          <TextArea name="quote" rows="3" placeholder="Quote" />
                          <ErrorMessage name="quote">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Summary & Description (Meta Tag)</label>
                          <TextArea name="summary" rows="3" placeholder="Summary & Description (Meta Tag)" />
                          <ErrorMessage name="summary">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Keywords (Meta Tag, Use Comma to separate keywords)</label>
                          <FormGroup className="input-payment-box">
                            <Field
                              name="keywords"
                              type="text"
                              className="form-control"
                              placeholder="Keywords (Meta Tag)"
                              disabled={isLoading}
                            />
                            <ErrorMessage name="keywords">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Offer Url</label>
                          <FormGroup className="input-payment-box">
                            <Field name="offerUrl" type="text" className="form-control" placeholder="Offer Url" disabled={isLoading} />
                            <ErrorMessage name="offerUrl">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="product-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="form-head-txt">Review Rating Numbers</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-2 mt-3 mb-1">
                          <label className="control-label">1 Star</label>
                          <FormGroup className="input-payment-box">
                            <Field name="s1_rating" type="number" className="form-control" placeholder="" disabled={isLoading} />
                            <ErrorMessage name="s1_rating">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                        <div className="col-sm-2 mt-3 mb-1">
                          <label className="control-label">2 Star</label>
                          <FormGroup className="input-payment-box">
                            <Field name="s2_rating" type="number" className="form-control" placeholder="" disabled={isLoading} />
                            <ErrorMessage name="s2_rating">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                        <div className="col-sm-2 mt-3 mb-1">
                          <label className="control-label">3 Star</label>
                          <FormGroup className="input-payment-box">
                            <Field name="s3_rating" type="number" className="form-control" placeholder="" disabled={isLoading} />
                            <ErrorMessage name="s3_rating">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                        <div className="col-sm-2 mt-3 mb-1">
                          <label className="control-label">4 Star</label>
                          <FormGroup className="input-payment-box">
                            <Field name="s4_rating" type="number" className="form-control" placeholder="" disabled={isLoading} />
                            <ErrorMessage name="s4_rating">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                        <div className="col-sm-2 mt-3 mb-1">
                          <label className="control-label">5 Star</label>
                          <FormGroup className="input-payment-box">
                            <Field name="s5_rating" type="number" className="form-control" placeholder="" disabled={isLoading} />
                            <ErrorMessage name="s5_rating">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="product-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="form-head-txt">Product Ratings</p>
                        </div>
                      </div>
                      {
                        <div className="row">
                          <div className="col-sm-12 mt-3 mb-1">
                            <label className="control-label">Overall Rating</label>
                            <FormGroup className="input-payment-box">
                              <RangeBarBox name="overallRating" />
                              <ErrorMessage name="overallRating">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                      }
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Effectiveness</label>
                          <FormGroup className="input-payment-box">
                            <RangeBarBox name="effectiveness" />
                            <ErrorMessage name="effectiveness">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Speed Results</label>
                          <FormGroup className="input-payment-box">
                            <RangeBarBox name="speedResults" />
                            <ErrorMessage name="speedResults">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Ingredient Quality</label>
                          <FormGroup className="input-payment-box">
                            <RangeBarBox name="ingredientQuality" />
                            <ErrorMessage name="ingredientQuality">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Long Term Results</label>
                          <FormGroup className="input-payment-box">
                            <RangeBarBox name="longTermResults" />
                            <ErrorMessage name="longTermResults">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="product-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="form-head-txt">Pros and Cons</p>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Pros (Use new line for each)</label>
                          <TextArea name="pros" rows="5" placeholder="Pros" />
                          <ErrorMessage name="pros">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Cons (Use new line for each)</label>
                          <TextArea name="cons" rows="5" placeholder="Cons" />
                          <ErrorMessage name="cons">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                        </div>
                      </div>
                    </div>
                    <div className="product-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="form-head-txt">Ingredients Body</p>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="col-sm-12 mt-4 mb-4">
                          <HtmlEditor templateBodyChange={ingredientsBodyChange} initialValue={productReviewItem?.ingredientsBody} />
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">How to use (Use new line for each)</label>
                          <TextArea name="howToUseBody" rows="5" placeholder="How to use" />
                          <ErrorMessage name="howToUseBody">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                        </div>
                      </div>
                    </div>
                    <div className="product-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="form-head-txt">Compare To Popular Products</p>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="col-sm-12 mt-3 mb-3">
                          <label className="control-label">Compare Popular Products Text</label>
                          <TextArea name="comparePopularProductsText" rows="5" placeholder="Compare Popular Products Text" />
                          <ErrorMessage name="comparePopularProductsText">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mb-3">
                          <label className="control-label">Compare Popular Products Image</label>
                          <br />
                          <FormikUploadImgInput
                            name="comparePopularProductsImg"
                            imgSrc={
                              productReviewItem.comparePopularProductsImg
                                ? `${localStorage.getItem("tg-site-domain")}/content/uploads/img/${
                                    productReviewItem.comparePopularProductsImg
                                  }`
                                : ""
                            }
                          />
                          <ErrorMessage name="comparePopularProductsImg">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                        </div>
                      </div>
                    </div>
                    <div className="product-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="form-head-txt">Extra Body</p>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="col-sm-12 mt-4 mb-4">
                          <HtmlEditor templateBodyChange={extraBodyChange} initialValue={productReviewItem?.extraBody} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="product-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12 mb-3">
                          <p>
                            <b>Upload product image</b>
                          </p>
                          <FormikUploadImgInput
                            name="productImg"
                            imgSrc={
                              productReviewItem?.productImg
                                ? `${localStorage.getItem("tg-site-domain")}/content/uploads/img/${productReviewItem?.productImg}`
                                : ""
                            }
                          />
                          <ErrorMessage name="productImg">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                        </div>
                      </div>
                    </div>
                    <div className="product-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12">
                          <label className="control-label">Select Snippet Type</label>
                          <FormGroup className="input-payment-box">
                            <Field
                              name="snippetType"
                              as="select"
                              className="form-control"
                              disabled={isLoading}
                              onClick={(e) => {
                                changeSnippetType(e);
                              }}
                            >
                              <option value="TYPE_1">NORMAL</option>
                              <option value="TYPE_2">PRODUCT</option>
                            </Field>
                            <ErrorMessage name="snippetType">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      {snippetType !== "TYPE_2" && (
                        <div className="row">
                          <div className="col-sm-12 mt-3 mb-1">
                            <label className="control-label">Select Snippet</label>
                            <FormGroup className="input-payment-box">
                              <SnippetSelectDropdown className="form-control" name="snippet" />
                              <ErrorMessage name="snippet">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                      )}
                      {snippetType === "TYPE_2" && (
                        <div className="row">
                          <div className="col-sm-12 mt-3 mb-1">
                            <label className="control-label">Select Product</label>
                            <FormGroup className="input-payment-box">
                              <ProductSelectDropdown className="form-control" name="product" />
                              <ErrorMessage name="product">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Select Author</label>
                          <FormGroup className="input-payment-box">
                            <AuthorSelectDropdown className="form-control" name="author" />
                            <ErrorMessage name="author">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Category</label>
                          <FormGroup className="input-payment-box">
                            <Field name="category" as="select" className="form-control" placeholder="Category" disabled={isLoading}>
                              <option value="Available">Available</option>
                              <option value="Sold Out">Sold Out</option>
                            </Field>
                            <ErrorMessage name="category">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Disclaimer Header</label>
                          <FormGroup className="input-payment-box">
                            <Field
                              name="disclaimerHeader"
                              type="text"
                              className="form-control"
                              placeholder="Disclaimer Header"
                              disabled={isLoading}
                            />
                            <ErrorMessage name="disclaimerHeader">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Add to Google Search</label>
                          <FormGroup className="input-payment-box">
                            <Field name="addToGoogleSearch" as="select" className="form-control" placeholder="Select" disabled={isLoading}>
                              <option value="ADD">Add</option>
                              <option value="DONT">Don't Add</option>
                            </Field>
                            <ErrorMessage name="addToGoogleSearch">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="product-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="form-head-txt">Publish</p>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="col-sm-8 mt-4 mb-4">
                          {isFailSubmission && (
                            <div className="container">
                              <div className="row">
                                <div className="col text-center">
                                  <>
                                    <Alert color="danger">
                                      <p className="mb-0">{errorMsg.sub}</p>
                                    </Alert>
                                  </>
                                </div>
                              </div>
                            </div>
                          )}
                          {isSuccessSubmission && (
                            <div className="container">
                              <div className="row">
                                <div className="col text-center">
                                  <>
                                    <Alert color="success">
                                      <p className="mb-0">Page Updated!</p>
                                    </Alert>
                                  </>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="container">
                            <div className="row d-flex justify-content-center">
                              <div className="col-sm-12 text-center">
                                <button type="submit" className="button-submit-style" disabled={isLoading}>
                                  Add Product Review Page
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProductReviewForm;
