import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert, FormGroup } from "reactstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import { loginEnd } from "../../../api/authAPI";

import "./Login.css";
import { readAllSites } from "../../../api/siteAPI";

const Login = () => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [openSelectSiteModal, setOpenSelectSiteModal] = useState(false);
  const [siteItems, setSiteItems] = useState([]);
  const [pending, setPending] = useState(true);

  function successSubmission(data) {
    localStorage.setItem("tg-auth-firstName", data.firstName);
    localStorage.setItem("tg-auth-lastName", data.lastName);
    localStorage.setItem("tg-auth-email", data.email);
    localStorage.setItem("tg-auth-token", data.token);
    localStorage.setItem("tg-auth-role", data.role);
    localStorage.setItem("tg-is-valid-auth", true);

    readAllSites().then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setSiteItems(data["data"]);
            setPending(false);
            setIsLoading(false);
            setOpenSelectSiteModal(true);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }

  function failSubmission(head, sub) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  function siteNameChange(e) {
    localStorage.setItem("tg-site-domain", e.target.value);
    window.location.href = "/users";
  }

  return (
    <>
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-sm-12">
            <div className="login-form-div">
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .max(100, "Email cannot exceed 100 characters")
                    .email("Invalid Email")
                    .required("Required"),
                  password: Yup.string()
                    .min(2, "Password cannot smaller than 2 characters")
                    .max(60, "Password cannot exceed 60 characters")
                    .required("Required"),
                })}
                onSubmit={(values) => {
                  setIsLoading(true);
                  setIsFailSubmission(false);

                  loginEnd({
                    email: values.email,
                    password: values.password,
                  }).then((data) => {
                    try {
                      if (data["result"]) {
                        if (data["result"] === "SUCCESS") {
                          successSubmission(data["data"]);
                        } else if (data["result"] === "FAILED") {
                          failSubmission(
                            "Error!",
                            data["data"]["errorMessage"]
                          );
                        } else {
                          failSubmission("Error!", "Please try again later!");
                        }
                      } else {
                        failSubmission("Error!", "Please try again later!");
                      }
                    } catch (error) {
                      failSubmission("Error!", "Please try again later!");
                    }
                  });
                }}
              >
                <Form>
                  <div className="row d-flex justify-content-center">
                    <div className="col-sm-4 mb-4">
                      <div className="row">
                        <div className="col-sm-12 mb-4 mt-5">
                          <h5 className="head-text">
                            Enter your Login Details
                          </h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mb-3">
                          <FormGroup className="input-box">
                            <Field
                              name="email"
                              type="text"
                              className="form-control"
                              placeholder="Email"
                              disabled={isLoading}
                            />
                            <ErrorMessage name="email">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mb-3">
                          <FormGroup className="input-box">
                            <Field
                              name="password"
                              type="password"
                              className="form-control"
                              placeholder="Password"
                              disabled={isLoading}
                            />
                            <ErrorMessage name="password">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      {isFailSubmission && (
                        <div className="container">
                          <div className="row">
                            <div className="col text-center">
                              <>
                                <Alert color="danger">
                                  {/* <p>{errorMsg.head}</p>
                                <hr /> */}
                                  <p className="mb-0">{errorMsg.sub}</p>
                                </Alert>
                              </>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="container">
                        <div className="row">
                          <div className="col-sm-12 text-center">
                            <button
                              type="submit"
                              className="button-submit-style"
                              disabled={isLoading}
                            >
                              Login to App
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {!pending && (
        <Modal
          isOpen={openSelectSiteModal}
          toggle={() => setOpenSelectSiteModal(!openSelectSiteModal)}
        >
          <ModalHeader
            className="modal-head"
            toggle={() => setOpenSelectSiteModal(!openSelectSiteModal)}
          >
            Select site Name
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-sm-12 mb-3">
                <select
                  as="select"
                  className="form-control"
                  onChange={(e) => {
                    siteNameChange(e);
                  }}
                  defaultValue=""
                >
                  <option value="" disabled>
                    Select Domain
                  </option>
                  {siteItems.map((item, index) => (
                    <option value={item.domain} key={index}>
                      {item.domain}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default Login;
