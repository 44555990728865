import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert, FormGroup } from "reactstrap";

import { updateCustomSnippet } from "../../../../api/snippetAPI";
import HtmlEditor from "../../../../components/HtmlEditor/HtmlEditor";

import "./EditCustomSnippetForm.css";

const EditCustomSnippetForm = ({ snippetItem }) => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [templateBody, setTemplateBody] = useState(snippetItem?.templateBody);

  console.log(snippetItem);

  function successSubmission() {
    window.location.href = "/snippets";
  }

  function failSubmission(head, sub) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  function templateBodyChange(value) {
    setTemplateBody(value);
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="">
            <Formik
              enableReinitialize
              initialValues={{
                name: snippetItem?.name,
                templateBody: snippetItem?.templateBody,
              }}
              validationSchema={Yup.object({
                name: Yup.string()
                  .min(2, "Name cannot smaller than 2 characters")
                  .max(100, "Name cannot exceed 130 characters")
                  .required("Required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                setIsFailSubmission(false);

                updateCustomSnippet({
                  snippetId: snippetItem?.snippetId,
                  name: values.name,
                  type: "CUSTOM",
                  templateBody: templateBody,
                }).then((data) => {
                  try {
                    if (data["result"]) {
                      if (data["result"] === "SUCCESS") {
                        successSubmission(data["data"]);
                      } else if (data["result"] === "FAILED") {
                        failSubmission("¡Error!", data["data"]["errorMessage"]);
                      } else {
                        failSubmission("Error!", "Please try again later!");
                      }
                    } else {
                      failSubmission("Error!", "Please try again later!");
                    }
                  } catch (error) {
                    failSubmission("Error!", "Please try again later!");
                  }
                });
              }}
            >
              <Form>
                <div className="row">
                  <div className="col-sm-8 mb-5">
                    <div className="article-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="form-head-txt">Snippet Details</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Title</label>
                          <FormGroup className="input-payment-box">
                            <Field
                              name="name"
                              type="text"
                              className="form-control"
                              placeholder="Name"
                              disabled={true}
                            />
                            <ErrorMessage name="name">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="article-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="form-head-txt">Template Body</p>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="col-sm-12 mt-4 mb-4">
                          <HtmlEditor
                            templateBodyChange={templateBodyChange}
                            initialValue={snippetItem?.templateBody}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="article-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="form-head-txt">Publish</p>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="col-sm-8 mt-4 mb-4">
                          {isFailSubmission && (
                            <div className="container">
                              <div className="row">
                                <div className="col text-center">
                                  <>
                                    <Alert color="danger">
                                      <p className="mb-0">{errorMsg.sub}</p>
                                    </Alert>
                                  </>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="container">
                            <div className="row d-flex justify-content-center">
                              <div className="col-sm-12 text-center">
                                <button
                                  type="submit"
                                  className="button-submit-style"
                                  disabled={isLoading}
                                >
                                  Update Snippet
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCustomSnippetForm;
