import AddCustomSnippetForm from "../../forms/addCustomSnippetForm/AddCustomSnippetForm";

function AddCustomSnippet() {
  return (
    <>
      <div className="container">
        <div className="row mt-3 mb-3">
          <div className="col-sm-12">
            <h2>Add Custom Snippet</h2>
          </div>
        </div>
        <AddCustomSnippetForm />
      </div>
    </>
  );
}

export default AddCustomSnippet;
