import { useMemo } from "react";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { IoIosAddCircle } from "react-icons/io";
import { FaEdit } from "react-icons/fa";

import { readAllHomePages } from "../../../../api/homeAPI";

import "./AllHomePages.css";

const AllHomePages = () => {
  const [homePageItems, setHomePageItems] = useState([]);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    readAllHomePages().then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setHomePageItems(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [homePageItems.length]);

  const columns = useMemo(
    () => [
      {
        name: "",
        selector: (row) => {
          return (
            <img
              className="home-head-img"
              src={`${localStorage.getItem(
                "tg-site-domain"
              )}/content/uploads/img/${row.headImg}`}
              alt="head"
            />
          );
        },
        grow: 1,
      },
      {
        name: "Title",
        selector: (row) => row.title,
        sortable: true,
        grow: 4,
      },
      {
        name: "Created",
        selector: (row) => row.created,
        sortable: true,
      },
      {
        name: "",
        selector: (row) => {
          return (
            <>
              <a
                className="btn-link-style home-icon"
                href={`/home/${row.homeId}`}
              >
                <FaEdit />
              </a>
            </>
          );
        },
      },
    ],
    []
  );

  const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      style: {
        fontSize: "16px",
      },
    },
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <button
            type="button"
            className="button-add-style"
            onClick={() => {
              window.location.href = "/add-home";
            }}
          >
            <IoIosAddCircle /> Add a Home Page
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="table_outer_div">
            <DataTable
              title="Home Pages"
              data={homePageItems}
              columns={columns}
              fixedHeader
              fixedHeaderScrollHeight="100%"
              pagination
              progressPending={pending}
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllHomePages;
