import { useMemo } from "react";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import { readAllMessages } from "../../../../api/messageAPI";

import "./AllMessages.css";

const AllMessages = () => {
  const [messageItems, setMessageItems] = useState([]);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    readAllMessages().then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setMessageItems(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [messageItems.length]);

  const columns = useMemo(
    () => [
      {
        name: "",
        selector: (row) => {
          return (
            <div className="review-name-div">
              <h4>{row.name}</h4>
              <p>{row.email}</p>
              <span>{row.ip}</span>
            </div>
          );
        },
        grow: 2,
      },
      {
        name: "Message",
        selector: (row) => {
          return (
            <textarea
              className="review-box form-control"
              name="message"
              rows="8"
              value={row.comment}
              disabled
            />
          );
        },
        sortable: true,
        grow: 4,
      },
      {
        name: "Created",
        selector: (row) => row.created,
        sortable: true,
        grow: 1,
      },
    ],
    []
  );

  const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      style: {
        fontSize: "16px",
      },
    },
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="table_outer_div">
            <DataTable
              title="Messages"
              data={messageItems}
              columns={columns}
              fixedHeader
              fixedHeaderScrollHeight="100%"
              pagination
              progressPending={pending}
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllMessages;
