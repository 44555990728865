import AddNormalSnippetForm from "../../forms/addNormalSnippetForm/AddNormalSnippetForm";

function AddNormalSnippet() {
  return (
    <>
      <div className="container">
        <div className="row mt-3 mb-3">
          <div className="col-sm-12">
            <h2>Add Normal Snippet</h2>
          </div>
        </div>
        <AddNormalSnippetForm />
      </div>
    </>
  );
}

export default AddNormalSnippet;
