import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import AllReviewsByArticle from "../../../review/pages/allReviewsByArticle/AllReviewsByArticle";
import { AiFillPlusCircle } from "react-icons/ai";
import AddReviewForm from "../../../review/forms/addReviewForm/AddReviewForm";
import { readArticleById } from "../../../../api/articleAPI";
import EditArticleForm from "../../forms/editArticleForm/EditArticleForm";

function EditArticlePage() {
  const { articleId } = useParams();
  const [articleItem, setArticleItem] = useState(null);
  const [pending, setPending] = useState(false);
  const [openAddReviewModal, setOpenAddReviewModal] = useState(false);

  useEffect(() => {
    readArticleById({ articleId }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setArticleItem(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [articleItem?.articleId, articleId]);

  return (
    <>
      {!pending && (
        <div className="container">
          <div className="row mt-3 mb-3">
            <div className="col-sm-12">
              <h2>{articleItem?.title}</h2>
            </div>
          </div>
          {articleItem && <EditArticleForm articleItem={articleItem} />}
          <div className="row mb-3">
            <div className="col-sm-8">
              <h2>{articleItem?.title} Review Section</h2>
            </div>
            <div className="col-sm-4">
              <button
                type="button"
                className="button-add-style"
                onClick={() => {
                  setOpenAddReviewModal(true);
                }}
              >
                <AiFillPlusCircle /> Add a Review
              </button>
            </div>
          </div>
          {articleItem && (
            <AllReviewsByArticle
              articleId={articleItem?.articleId}
              openAddReviewModal={openAddReviewModal}
            />
          )}
          <Modal
            isOpen={openAddReviewModal}
            toggle={() => setOpenAddReviewModal(!openAddReviewModal)}
          >
            <ModalHeader
              className="modal-head"
              toggle={() => setOpenAddReviewModal(!openAddReviewModal)}
            >
              Add New Comment
            </ModalHeader>
            <ModalBody>
              <AddReviewForm
                articleId={articleId}
                setOpenAddReviewModal={setOpenAddReviewModal}
              />
            </ModalBody>
          </Modal>
        </div>
      )}
    </>
  );
}

export default EditArticlePage;
