import axios from "axios";

const siteDomain = localStorage.getItem("tg-site-domain");

export const readSiteData = async () => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/site/read_site_data.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const updateSiteData = async ({
  siteId,
  domain,
  siteName,
  themeColor,
  favicon,
  companyName,
  companyEmail,
  diclaimerFda,
  applyFooterDisclaimers,
  diclaimerFooterAdvert,
  mode,
  googleCaptchaSecreteKey,
  googleCaptchaSiteKey,
}) => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("siteId", siteId);
    formData.append("domain", domain);
    formData.append("siteName", siteName);
    formData.append("themeColor", themeColor);
    formData.append("favicon", favicon);
    formData.append("companyName", companyName);
    formData.append("companyEmail", companyEmail);
    formData.append("diclaimerFda", diclaimerFda);
    formData.append("applyFooterDisclaimers", applyFooterDisclaimers);
    formData.append("diclaimerFooterAdvert", diclaimerFooterAdvert);
    formData.append("mode", mode);
    formData.append("googleCaptchaSecreteKey", googleCaptchaSecreteKey);
    formData.append("googleCaptchaSiteKey", googleCaptchaSiteKey);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/site/update.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const addSite = async ({
  domain,
  siteName,
  themeColor,
  favicon,
  companyName,
  companyEmail,
  diclaimerFda,
}) => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("domain", domain);
    formData.append("siteName", siteName);
    formData.append("themeColor", themeColor);
    formData.append("favicon", favicon);
    formData.append("companyName", companyName);
    formData.append("companyEmail", companyEmail);
    formData.append("diclaimerFda", diclaimerFda);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/site/add.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readAllSites = async () => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/site/read_all.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
