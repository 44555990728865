export default function renderSnippetType(type) {
  switch (type) {
    case "TYPE_1":
      return (
        <h3>
          <span className="ui orange label">Normal</span>
        </h3>
      );
    case "TYPE_2":
      return (
        <h3>
          <span className="ui blue label">Product</span>
        </h3>
      );
    case "TYPE_3":
      return (
        <h3>
          <span className="ui olive label">Recommended</span>
        </h3>
      );
    case "CUSTOM":
      return (
        <h3>
          <span className="ui green label">Custom</span>
        </h3>
      );
    default:
      return (
        <h3>
          <span className="ui teal label">Not Mention</span>
        </h3>
      );
  }
}

export function renderReviewState(state) {
  switch (state) {
    case "PUBLIC":
      return (
        <h3>
          <span className="ui green label">Public</span>
        </h3>
      );
    case "PRIVATE":
      return (
        <h3>
          <span className="ui red label">Private</span>
        </h3>
      );
    default:
      return (
        <h3>
          <span className="ui teal label">Not Mention</span>
        </h3>
      );
  }
}
