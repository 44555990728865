import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert, FormGroup } from "reactstrap";

import { updateUserRole } from "../../../../api/authAPI";

import "./EditRole.css";

const EditRoleState = ({ userObject, setOpenEditModal }) => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);

  function successSubmission() {
    setOpenEditModal(false);
    window.location.href = "/users";
  }

  function failSubmission(head, sub) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-sm-12">
          <div className="invoice-form-div">
            <Formik
              initialValues={{
                userId: userObject && userObject.userId,
                role: userObject && userObject.role,
              }}
              validationSchema={Yup.object({
                role: Yup.string().required("Required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                setIsFailSubmission(false);
                updateUserRole({
                  userId: values.userId,
                  role: values.role,
                }).then((data) => {
                  try {
                    if (data["result"]) {
                      if (data["result"] === "SUCCESS") {
                        successSubmission(data["data"]);
                      } else if (data["result"] === "FAILED") {
                        failSubmission("¡Error!", data["data"]["errorMessage"]);
                      } else {
                        failSubmission("Error!", "Please try again later!");
                      }
                    } else {
                      failSubmission("Error!", "Please try again later!");
                    }
                  } catch (error) {
                    failSubmission("Error!", "Please try again later!");
                  }
                });
              }}
            >
              <Form>
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-8 mb-4">
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="role"
                            as="select"
                            className="form-control"
                            placeholder="Select user Role"
                            disabled={isLoading}
                          >
                            <option value="ADMIN">ADMIN</option>
                            <option value="EDITOR">EDITOR</option>
                          </Field>
                          <ErrorMessage name="role">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    {isFailSubmission && (
                      <div className="container">
                        <div className="row">
                          <div className="col text-center">
                            <>
                              <Alert color="danger">
                                {/* <p>{errorMsg.head}</p>
                                <hr /> */}
                                <p className="mb-0">{errorMsg.sub}</p>
                              </Alert>
                            </>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12 text-center">
                          <button
                            type="submit"
                            className="button-submit-style"
                            disabled={isLoading}
                          >
                            Update User Role
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditRoleState;
