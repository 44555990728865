import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import { readProductReviewById } from "../../../../api/productReviewAPI";
import AllReviewsByArticle from "../../../review/pages/allReviewsByArticle/AllReviewsByArticle";
import EditProductReviewForm from "../../forms/editProductReviewForm/EditProductReviewForm";
import { AiFillPlusCircle } from "react-icons/ai";
import AddReviewForm from "../../../review/forms/addReviewForm/AddReviewForm";

function EditProductReviewPage() {
  const { productReviewId } = useParams();
  const [productReviewItem, setProductReviewItem] = useState(null);
  const [pending, setPending] = useState(false);
  const [openAddReviewModal, setOpenAddReviewModal] = useState(false);

  useEffect(() => {
    readProductReviewById({ productReviewId }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setProductReviewItem(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [productReviewItem?.productReviewId, productReviewId]);

  return (
    <>
      {!pending && (
        <div className="container">
          <div className="row mt-3 mb-3">
            <div className="col-sm-12">
              <h2>{productReviewItem?.title}</h2>
            </div>
          </div>
          {productReviewItem && (
            <EditProductReviewForm productReviewItem={productReviewItem} />
          )}
          <div className="row mb-3">
            <div className="col-sm-8">
              <h2>{productReviewItem?.title} Review Section</h2>
            </div>
            <div className="col-sm-4">
              <button
                type="button"
                className="button-add-style"
                onClick={() => {
                  setOpenAddReviewModal(true);
                }}
              >
                <AiFillPlusCircle /> Add a Review
              </button>
            </div>
          </div>
          {productReviewItem && (
            <AllReviewsByArticle
              articleId={productReviewItem?.productReviewId}
              openAddReviewModal={openAddReviewModal}
            />
          )}
          <Modal
            isOpen={openAddReviewModal}
            toggle={() => setOpenAddReviewModal(!openAddReviewModal)}
          >
            <ModalHeader
              className="modal-head"
              toggle={() => setOpenAddReviewModal(!openAddReviewModal)}
            >
              Add New Comment
            </ModalHeader>
            <ModalBody>
              <AddReviewForm
                articleId={productReviewId}
                setOpenAddReviewModal={setOpenAddReviewModal}
              />
            </ModalBody>
          </Modal>
        </div>
      )}
    </>
  );
}

export default EditProductReviewPage;
