import React, { useState } from "react";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  useField,
  useFormikContext,
} from "formik";
import * as Yup from "yup";
import { Alert, FormGroup } from "reactstrap";

import { addNewSnippet } from "../../../../api/snippetAPI";

import "./AddNormalSnippetForm.css";

const TextArea = ({ label, ...props }) => {
  const [field] = useField(props);
  return (
    <>
      <textarea className="form-control" {...field} {...props} />
    </>
  );
};

const UploadImgInput = ({ label, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <>
      <input
        name={props.name}
        type="file"
        onChange={(event) => {
          setFieldValue(field.name, event.currentTarget.files[0]);
        }}
      />
    </>
  );
};

const AddNormalSnippetForm = () => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);

  function successSubmission() {
    window.location.href = "/snippets";
  }

  function failSubmission(head, sub) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="">
            <Formik
              initialValues={{
                name: "",
                subTxt1: "",
                subTxt2: "",
                subTxt3: "",
                subTxt4: "",
                promoImg: "",
                img1: "",
                img2: "",
              }}
              validationSchema={Yup.object({
                name: Yup.string()
                  .min(2, "Name cannot smaller than 2 characters")
                  .max(100, "Name cannot exceed 100 characters")
                  .required("Required"),
                subTxt1: Yup.string()
                  .min(20, "Sub Text One cannot smaller than 20 characters")
                  .max(750, "Sub Text One cannot exceed 750 characters")
                  .required("Required"),
                subTxt2: Yup.string()
                  .min(20, "Sub Text Two cannot smaller than 20 characters")
                  .max(750, "Sub Text Two cannot exceed 750 characters")
                  .required("Required"),
                subTxt3: Yup.string()
                  .min(20, "Sub Text Three cannot smaller than 20 characters")
                  .max(750, "Sub Text Three cannot exceed 750 characters")
                  .required("Required"),
                subTxt4: Yup.string()
                  .min(20, "Sub Text Four cannot smaller than 20 characters")
                  .max(750, "Sub Text Four cannot exceed 750 characters")
                  .required("Required"),
                promoImg: Yup.mixed().required("Img File is required"),
                img1: Yup.mixed().required("Img File is required"),
                img2: Yup.mixed().required("Img File is required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                setIsFailSubmission(false);

                addNewSnippet({
                  name: values.name,
                  subTxt1: values.subTxt1,
                  subTxt2: values.subTxt2,
                  subTxt3: values.subTxt3,
                  subTxt4: values.subTxt4,
                  promoImg: values.promoImg,
                  img1: values.img1,
                  img2: values.img2,
                  type: "TYPE_1",
                }).then((data) => {
                  try {
                    if (data["result"]) {
                      if (data["result"] === "SUCCESS") {
                        successSubmission(data["data"]);
                      } else if (data["result"] === "FAILED") {
                        failSubmission("¡Error!", data["data"]["errorMessage"]);
                      } else {
                        failSubmission("Error!", "Please try again later!");
                      }
                    } else {
                      failSubmission("Error!", "Please try again later!");
                    }
                  } catch (error) {
                    failSubmission("Error!", "Please try again later!");
                  }
                });
              }}
            >
              <Form>
                <div className="row">
                  <div className="col-sm-8 mb-5">
                    <div className="snippet-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="form-head-txt">Snippet Details</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Title</label>
                          <FormGroup className="input-payment-box">
                            <Field
                              name="name"
                              type="text"
                              className="form-control"
                              placeholder="Name"
                              disabled={isLoading}
                            />
                            <ErrorMessage name="name">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Sub Text One</label>
                          <TextArea
                            name="subTxt1"
                            rows="6"
                            placeholder="Sub Text One"
                          />
                          <ErrorMessage name="subTxt1">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Sub Text Two</label>
                          <TextArea
                            name="subTxt2"
                            rows="6"
                            placeholder="Sub Text Two"
                          />
                          <ErrorMessage name="subTxt2">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">
                            Sub Text Three
                          </label>
                          <TextArea
                            name="subTxt3"
                            rows="6"
                            placeholder="Sub Text Three"
                          />
                          <ErrorMessage name="subTxt3">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Sub Text Four</label>
                          <TextArea
                            name="subTxt4"
                            rows="6"
                            placeholder="Sub Text Four"
                          />
                          <ErrorMessage name="subTxt4">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="snippet-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12 mb-3">
                          <p>
                            <b>Upload Promotion image</b>
                          </p>
                          <UploadImgInput name="promoImg" />
                          <ErrorMessage name="promoImg">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                    </div>
                    <div className="snippet-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12 mb-3">
                          <p>
                            <b>Image One</b>
                          </p>
                          <UploadImgInput name="img1" />
                          <ErrorMessage name="img1">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                    </div>
                    <div className="snippet-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12 mb-3">
                          <p>
                            <b>Image Two</b>
                          </p>
                          <UploadImgInput name="img2" />
                          <ErrorMessage name="img2">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                    </div>
                    <div className="snippet-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="form-head-txt">Publish</p>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="col-sm-8 mt-4 mb-4">
                          {isFailSubmission && (
                            <div className="container">
                              <div className="row">
                                <div className="col text-center">
                                  <>
                                    <Alert color="danger">
                                      <p className="mb-0">{errorMsg.sub}</p>
                                    </Alert>
                                  </>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="container">
                            <div className="row d-flex justify-content-center">
                              <div className="col-sm-12 text-center">
                                <button
                                  type="submit"
                                  className="button-submit-style"
                                  disabled={isLoading}
                                >
                                  Add Snippet
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNormalSnippetForm;
