import React, { Suspense } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";

import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import Page404 from "./RootPage/page404/Page404";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Login from "./RootPage/auth/login/Login";
import AllUsers from "./RootPage/auth/allUsers/AllUsers";
import AllAuthors from "./RootPage/author/allAuthors/AllAuthors";
import AllArticles from "./RootPage/article/pages/allArticles/AllArticles";
import AddArticle from "./RootPage/article/pages/addArticle/AddArticle";
import AllSnippetsPage from "./RootPage/snippet/pages/allSnippetsPage/AllSnippetsPage";
import AddNormalSnippet from "./RootPage/snippet/pages/addNormalSnippetPage/AddNormalSnippetPage";
import AddCustomSnippet from "./RootPage/snippet/pages/addCustomSnippetPage/AddCustomSnippetPage";
import EditNormalSnippet from "./RootPage/snippet/pages/editNormalSnippetPage/EditNormalSnippetPage";
import EditCustomSnippet from "./RootPage/snippet/pages/editCustomSnippetPage/EditCustomSnippetPage";
import AddProductReviewPage from "./RootPage/productReview/pages/addProductReviewPage/AddProductReviewPage";
import AllProductReviewPages from "./RootPage/productReview/pages/allProductReviewPages/AllProductReviewPages";
import AllReviews from "./RootPage/review/pages/allReviews/AllReviews";
import EditProductReviewPage from "./RootPage/productReview/pages/editProductReviewPage/EditProductReviewPage";
import EditArticlePage from "./RootPage/article/pages/editArticlePage/EditArticlePage";
import AddHome from "./RootPage/home/pages/addHome/AddHome";
import AllHomePages from "./RootPage/home/pages/allHomePages/AllHomePages";
import EditHomePage from "./RootPage/home/pages/editHomePage/EditHomePage";
import AllMessages from "./RootPage/message/pages/allMessages/AllMessages";
import Root from "./RootPage/root";
import AllSites from "./RootPage/site/pages/allSites/AllSites";
import Settings from "./RootPage/config/pages/Settings";
import AddSite from "./RootPage/site/pages/addSite/AddSite";

function App() {
  const { pathname } = useLocation();

  return (
    <ScrollToTop>
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
          <ProtectedRoute path="/users" exact component={AllUsers} />
          <ProtectedRoute path="/authors" exact component={AllAuthors} />
          <ProtectedRoute path="/articles" exact component={AllArticles} />
          <ProtectedRoute path="/add-article" exact component={AddArticle} />
          <ProtectedRoute
            path="/article/:articleId"
            exact
            component={EditArticlePage}
          />
          <ProtectedRoute
            path="/product-review-pages"
            exact
            component={AllProductReviewPages}
          />
          <ProtectedRoute
            path="/add-product-review"
            exact
            component={AddProductReviewPage}
          />
          <ProtectedRoute
            path="/product-review-page/:productReviewId"
            exact
            component={EditProductReviewPage}
          />
          <ProtectedRoute path="/snippets" exact component={AllSnippetsPage} />
          <ProtectedRoute
            path="/add-normal-snippet"
            exact
            component={AddNormalSnippet}
          />
          <ProtectedRoute
            path="/add-custom-snippet"
            exact
            component={AddCustomSnippet}
          />
          <ProtectedRoute
            path="/edit-normal-snippet/:snippetId"
            exact
            component={EditNormalSnippet}
          />
          <ProtectedRoute
            path="/edit-custom-snippet/:snippetId"
            exact
            component={EditCustomSnippet}
          />
          <ProtectedRoute path="/reviews" exact component={AllReviews} />
          <ProtectedRoute path="/settings" exact component={Settings} />
          <ProtectedRoute path="/home" exact component={AllHomePages} />
          <ProtectedRoute path="/add-home" exact component={AddHome} />
          <ProtectedRoute path="/home/:homeId" exact component={EditHomePage} />
          <ProtectedRoute path="/message" exact component={AllMessages} />
          <ProtectedRoute path="/sites" exact component={AllSites} />
          <ProtectedRoute path="/add-site" exact component={AddSite} />
          <ProtectedRoute path="/" exact component={Root} />
          <Route path="/login" exact render={(props) => <Login />} />
          <Route path="*" exact={true} component={Page404} />
        </Switch>
      </Suspense>
    </ScrollToTop>
  );
}

export default App;
