import React, { useState } from "react";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  useField,
  useFormikContext,
} from "formik";
import * as Yup from "yup";
import { Alert, FormGroup } from "reactstrap";

import { addSite } from "../../../../api/siteAPI";
import ColorPickerBox from "../../../../components/ColorPickerBox/ColorPickerBox";
import FormikUploadImgInput from "../../../../components/Formik/FormikUploadImgInput/FormikUploadImgInput";

import "./SiteAddForm.css";

const TextArea = ({ label, ...props }) => {
  const [field] = useField(props);
  return (
    <>
      <textarea className="form-control" {...field} {...props} />
    </>
  );
};

const ColorPickerBoxDiv = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <ColorPickerBox
      changeCurrentValue={(val) => {
        setFieldValue(field.name, val);
      }}
      selectedOption={field.value}
    />
  );
};

const SiteAddForm = () => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);

  function successSubmission() {
    window.location.href = "/sites";
  }

  function failSubmission(head, sub) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="">
            <Formik
              initialValues={{
                domain: "",
                siteName: "",
                themeColor: "#009434",
                favicon: "",
                companyName: "",
                companyEmail: "",
                diclaimerFda: "",
              }}
              validationSchema={Yup.object({
                domain: Yup.string()
                  .min(5, "Domain cannot smaller than 5 characters")
                  .max(100, "Domain cannot exceed 100 characters")
                  .required("Required"),
                siteName: Yup.string()
                  .min(4, "Site Name cannot smaller than 4 characters")
                  .max(50, "Site Name cannot exceed 50 characters")
                  .required("Required"),
                companyName: Yup.string()
                  .min(5, "Company Name cannot smaller than 5 characters")
                  .max(100, "Company Name cannot exceed 100 characters")
                  .required("Required"),
                companyEmail: Yup.string()
                  .min(2, "Company Email cannot smaller than 2 characters")
                  .max(100, "Company Email cannot exceed 100 characters")
                  .email("Invalid Email")
                  .required("Required"),
                themeColor: Yup.string()
                  .min(2, "Theme Color cannot smaller than 2 characters")
                  .max(10, "Theme Color cannot exceed 10 characters")
                  .required("Required"),
                favicon: Yup.mixed().required("Img File is required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                setIsFailSubmission(false);

                addSite({
                  domain: values.domain,
                  siteName: values.siteName,
                  themeColor: values.themeColor,
                  favicon: values.favicon,
                  companyName: values.companyName,
                  companyEmail: values.companyEmail,
                  diclaimerFda: values.diclaimerFda,
                }).then((data) => {
                  try {
                    if (data["result"]) {
                      if (data["result"] === "SUCCESS") {
                        successSubmission(data["data"]);
                      } else if (data["result"] === "FAILED") {
                        failSubmission("¡Error!", data["data"]["errorMessage"]);
                      } else {
                        failSubmission("Error!", "Please try again later!");
                      }
                    } else {
                      failSubmission("Error!", "Please try again later!");
                    }
                  } catch (error) {
                    failSubmission("Error!", "Please try again later!");
                  }
                });
              }}
            >
              <Form>
                <div className="row">
                  <div className="col-sm-8 mb-5">
                    <div className="site-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Site Name</label>
                          <FormGroup className="">
                            <Field
                              name="siteName"
                              type="text"
                              className="form-control"
                              placeholder="Site Name"
                              disabled={isLoading}
                            />
                            <ErrorMessage name="siteName">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">
                            Domain (Start with https)
                          </label>
                          <FormGroup className="">
                            <Field
                              name="domain"
                              type="text"
                              className="form-control"
                              placeholder="Domain"
                              disabled={isLoading}
                            />
                            <ErrorMessage name="domain">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Company Name</label>
                          <FormGroup className="">
                            <Field
                              name="companyName"
                              type="text"
                              className="form-control"
                              placeholder="Company Name"
                              disabled={isLoading}
                            />
                            <ErrorMessage name="companyName">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Company Email</label>
                          <FormGroup className="">
                            <Field
                              name="companyEmail"
                              type="text"
                              className="form-control"
                              placeholder="Company Email"
                              disabled={isLoading}
                            />
                            <ErrorMessage name="companyEmail">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">
                            Custom Disclaimer Text(If need)
                          </label>
                          <TextArea
                            name="diclaimerFda"
                            rows="3"
                            placeholder="Disclaimer Text"
                          />
                          <ErrorMessage name="diclaimerFda">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="site-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12 mb-3">
                          <p>
                            <b>Upload Favicon image</b>
                          </p>
                          <FormikUploadImgInput name="favicon" imgSrc="" />
                          <ErrorMessage name="favicon">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Theme Color</label>
                          <FormGroup className="input-payment-box">
                            <ColorPickerBoxDiv name="themeColor" />
                            <ErrorMessage name="themeColor">
                              {(msg) => (
                                <div style={{ color: "red" }}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="site-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="form-head-txt">Add new Site</p>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="col-sm-8 mt-4 mb-4">
                          {isFailSubmission && (
                            <div className="container">
                              <div className="row">
                                <div className="col text-center">
                                  <>
                                    <Alert color="danger">
                                      <p className="mb-0">{errorMsg.sub}</p>
                                    </Alert>
                                  </>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="container">
                            <div className="row d-flex justify-content-center">
                              <div className="col-sm-12 text-center">
                                <button
                                  type="submit"
                                  className="button-submit-style"
                                  disabled={isLoading}
                                >
                                  Add new Site
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteAddForm;
