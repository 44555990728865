import { useMemo } from "react";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { AiOutlineUserAdd } from "react-icons/ai";

import { readAllAuthors } from "../../../api/authorAPI";
import AddAuthorForm from "../forms/addAuthorForm/AddAuthorForm";
import { FaEdit } from "react-icons/fa";
import { Image } from "semantic-ui-react";

import "./AllAuthors.css";
import EditAuthorForm from "../forms/editAuthorForm/EditAuthorForm";

const AllAuthors = () => {
  const [authorItems, setAuthorItems] = useState([]);
  const [pending, setPending] = useState(true);
  const [openCreateAuthorModal, setOpenCreateAuthorModal] = useState(false);
  const [clickedAuthor, setClickedAuthor] = useState("");
  const [openUpdateAuthorModal, setOpenUpdateAuthorModal] = useState(false);

  useEffect(() => {
    readAllAuthors().then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setAuthorItems(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [authorItems.length]);

  const onClickAuthor = (value) => {
    setClickedAuthor(value);
    setOpenUpdateAuthorModal(true);
  };

  const columns = useMemo(
    () => [
      {
        name: "",
        selector: (row) => {
          return (
            <>
              <Image
                src={`${localStorage.getItem(
                  "tg-site-domain"
                )}/content/uploads/img/${row.imgPath}`}
                avatar
              />
              {`${row.firstName} ${row.lastName}`}
            </>
          );
        },
        grow: 1,
      },
      {
        name: "About",
        selector: (row) => (
          <textarea
            className="review-box form-control"
            name="comment"
            rows="8"
            value={row.about}
            disabled
          />
        ),
        sortable: true,
        grow: 3,
      },
      {
        name: "Created",
        selector: (row) => row.created,
        sortable: true,
        grow: 2,
      },
      {
        name: "",
        selector: (row) => {
          return (
            <>
              <p
                className="btn-link-style home-icon"
                onClick={() => {
                  onClickAuthor(row);
                }}
              >
                <FaEdit />
              </p>
            </>
          );
        },
      },
    ],
    []
  );

  const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      style: {
        fontSize: "16px",
      },
    },
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <button
            type="button"
            className="button-add-style"
            onClick={() => {
              setOpenCreateAuthorModal(true);
            }}
          >
            <AiOutlineUserAdd /> Add a Author
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="table_outer_div">
            <DataTable
              title="Authors"
              data={authorItems}
              columns={columns}
              fixedHeader
              fixedHeaderScrollHeight="100%"
              pagination
              progressPending={pending}
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
      <Modal
        isOpen={openCreateAuthorModal}
        toggle={() => setOpenCreateAuthorModal(!openCreateAuthorModal)}
      >
        <ModalHeader
          className="modal-head"
          toggle={() => setOpenCreateAuthorModal(!openCreateAuthorModal)}
        >
          Add New Author
        </ModalHeader>
        <ModalBody>
          <AddAuthorForm setOpenCreateAuthorModal={setOpenCreateAuthorModal} />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={openUpdateAuthorModal}
        toggle={() => setOpenUpdateAuthorModal(!openUpdateAuthorModal)}
      >
        <ModalHeader
          className="modal-head"
          toggle={() => setOpenUpdateAuthorModal(!openUpdateAuthorModal)}
        >
          Update Author
        </ModalHeader>
        <ModalBody>
          <EditAuthorForm
            authorItem={clickedAuthor}
            setOpenUpdateAuthorModal={setOpenUpdateAuthorModal}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AllAuthors;
