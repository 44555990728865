import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";

import { tokenCheck } from "../../api/authAPI";
import EditUser from "../../RootPage/auth/forms/editUser/EditUser";
import SideMenu from "../SideMenu/SideMenu";
import TopMenu from "../TopMenu/TopMenu";

import "./ProtectedRoute.css";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const [open, setOpen] = useState(false);
  const isAuthenticated = localStorage.getItem("tg-is-valid-auth");
  const siteDomain = localStorage.getItem("tg-site-domain");
  const [userId, setUserId] = useState(null);
  const [openSideBar, setOpenSideBar] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("tg-auth-token");
    if (token && siteDomain) {
      tokenCheck({
        token: token,
      }).then((data) => {
        try {
          if (data["result"]) {
            if (data["result"] === "SUCCESS") {
              if (data["data"]) {
                setUserId(data["data"].id);
              }
              localStorage.setItem("tg-is-valid-auth", true);
            } else if (data["result"] === "FAILED") {
              localStorage.setItem("tg-is-valid-auth", false);
              window.location.href = "/login";
            } else {
              localStorage.setItem("tg-is-valid-auth", false);
              window.location.href = "/login";
            }
          } else {
            localStorage.setItem("tg-is-valid-auth", false);
            window.location.href = "/login";
          }
        } catch (error) {
          localStorage.setItem("tg-is-valid-auth", false);
          window.location.href = "/login";
        }
      });
    } else {
      localStorage.setItem("tg-is-valid-auth", false);
      window.location.href = "/login";
    }
  });

  const handleCollapse = () => {
    setOpenSideBar(!openSideBar);
  };

  const handleProfileToggle = () => {
    setOpen(!open);
  };

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? (
          <>
            <Menu
              className={
                open
                  ? "profile-bar-menu"
                  : "profile-bar-menu profile-bar-menu-hide"
              }
              fixed="right"
              borderless
              vertical
            >
              <div className={open ? "" : "hide-menu-content"}>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="close-icon" onClick={() => setOpen(!open)}>
                      <Icon name="arrow alternate circle right outline" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mb-5">
                    {userId && <EditUser userId={userId} />}
                  </div>
                </div>
              </div>
            </Menu>
            <TopMenu
              handleCollapse={handleCollapse}
              handleProfileOpen={handleProfileToggle}
            />
            <div className="main-content">
              <div className="parent">
                <div className={(openSideBar ? "small-side " : "") + "side"}>
                  <SideMenu smallMenu={openSideBar} />
                </div>
              </div>
              <div
                className={(openSideBar ? "small-content " : "") + "content"}
              >
                <Component {...props} />
              </div>
            </div>
          </>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

export default ProtectedRoute;
