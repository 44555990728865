import axios from "axios";

const siteDomain = localStorage.getItem("tg-site-domain");

export const readAllProductReviewPages = async () => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/product_review/read_all.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const addNewProductReview = async ({
  title,
  productName,
  summary,
  quote,
  slug,
  keywords,
  productImg,
  addToGoogleSearch,
  authorId,
  offerUrl,
  snippetType,
  snippetId,
  productId,
  pros,
  cons,
  ingredientsBody,
  howToUseBody,
  comparePopularProductsText,
  comparePopularProductsImg,
  extraBody,
  overallRating,
  effectiveness,
  speedResults,
  ingredientQuality,
  longTermResults,
  s1_rating,
  s2_rating,
  s3_rating,
  s4_rating,
  s5_rating,
  category,
  disclaimerHeader,
}) => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("title", title);
    formData.append("productName", productName);
    formData.append("summary", summary);
    formData.append("quote", quote);
    formData.append("slug", slug);
    formData.append("keywords", keywords);
    formData.append("productImg", productImg);
    formData.append("addToGoogleSearch", addToGoogleSearch);
    formData.append("authorId", authorId);
    formData.append("offerUrl", offerUrl);
    formData.append("snippetType", snippetType);
    formData.append("snippetId", snippetId);
    formData.append("productId", productId);
    formData.append("pros", pros);
    formData.append("cons", cons);
    formData.append("ingredientsBody", ingredientsBody);
    formData.append("howToUseBody", howToUseBody);
    formData.append("comparePopularProductsText", comparePopularProductsText);
    formData.append("comparePopularProductsImg", comparePopularProductsImg);
    formData.append("extraBody", extraBody);
    formData.append("overallRating", overallRating);
    formData.append("effectiveness", effectiveness);
    formData.append("speedResults", speedResults);
    formData.append("ingredientQuality", ingredientQuality);
    formData.append("longTermResults", longTermResults);
    formData.append("s1_rating", s1_rating);
    formData.append("s2_rating", s2_rating);
    formData.append("s3_rating", s3_rating);
    formData.append("s4_rating", s4_rating);
    formData.append("s5_rating", s5_rating);
    formData.append("category", category);
    formData.append("disclaimerHeader", disclaimerHeader);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/product_review/create.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readProductReviewById = async ({ productReviewId }) => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("productReviewId", productReviewId);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/product_review/read_by_id.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const getProductList = async (word) => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("q", word);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/product_review/read_list.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const updateProductReview = async ({
  productReviewId,
  summary,
  quote,
  keywords,
  productImg,
  addToGoogleSearch,
  authorId,
  offerUrl,
  snippetType,
  snippetId,
  productId,
  pros,
  cons,
  ingredientsBody,
  howToUseBody,
  comparePopularProductsText,
  comparePopularProductsImg,
  extraBody,
  overallRating,
  effectiveness,
  speedResults,
  ingredientQuality,
  longTermResults,
  s1_rating,
  s2_rating,
  s3_rating,
  s4_rating,
  s5_rating,
  category,
  disclaimerHeader,
}) => {
  try {
    const token = localStorage.getItem("tg-auth-token");
    let formData = new FormData();

    formData.append("productReviewId", productReviewId);
    formData.append("summary", summary);
    formData.append("quote", quote);
    formData.append("keywords", keywords);
    formData.append("productImg", productImg);
    formData.append("addToGoogleSearch", addToGoogleSearch);
    formData.append("authorId", authorId);
    formData.append("offerUrl", offerUrl);
    formData.append("snippetType", snippetType);
    formData.append("snippetId", snippetId);
    formData.append("productId", productId);
    formData.append("pros", pros);
    formData.append("cons", cons);
    formData.append("ingredientsBody", ingredientsBody);
    formData.append("howToUseBody", howToUseBody);
    formData.append("comparePopularProductsText", comparePopularProductsText);
    formData.append("comparePopularProductsImg", comparePopularProductsImg);
    formData.append("extraBody", extraBody);
    formData.append("overallRating", overallRating);
    formData.append("effectiveness", effectiveness);
    formData.append("speedResults", speedResults);
    formData.append("ingredientQuality", ingredientQuality);
    formData.append("longTermResults", longTermResults);
    formData.append("s1_rating", s1_rating);
    formData.append("s2_rating", s2_rating);
    formData.append("s3_rating", s3_rating);
    formData.append("s4_rating", s4_rating);
    formData.append("s5_rating", s5_rating);
    formData.append("category", category);
    formData.append("disclaimerHeader", disclaimerHeader);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${siteDomain}/api/product_review/update.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
