import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { readBySnippetId } from "../../../../api/snippetAPI";
import EditNormalSnippetForm from "../../forms/editNormalSnippetForm/EditNormalSnippetForm";

function EditNormalSnippet() {
  const { snippetId } = useParams();
  const [snippetItem, setSnippetItem] = useState(null);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    readBySnippetId({ snippetId }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setSnippetItem(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [snippetItem?.articleId, snippetId]);

  return (
    <>
      <div className="container">
        <div className="row mt-3 mb-3">
          <div className="col-sm-12">
            <h2>Edit Normal Snippet</h2>
          </div>
        </div>
        {snippetItem && <EditNormalSnippetForm snippetItem={snippetItem} />}
      </div>
    </>
  );
}

export default EditNormalSnippet;
