import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage, useField, useFormikContext } from "formik";
import * as Yup from "yup";
import { Alert, FormGroup } from "reactstrap";

import { addNewArticle } from "../../../../api/articleAPI";
import SnippetList from "../../../../components/SnippetList/SnippetList";
import AuthorList from "../../../../components/AuthorList/AuthorList";
import HtmlEditor from "../../../../components/HtmlEditor/HtmlEditor";
import FormikUploadImgInput from "../../../../components/Formik/FormikUploadImgInput/FormikUploadImgInput";

import "./AddArticleForm.css";

const TextArea = ({ label, ...props }) => {
  const [field] = useField(props);
  return (
    <>
      <textarea className="form-control" {...field} {...props} />
    </>
  );
};

const SnippetSelectDropdown = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <SnippetList
      changeCurrentValue={(val) => {
        setFieldValue(field.name, val.code);
      }}
      selectedOption={field.value}
    />
  );
};

const AuthorSelectDropdown = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <AuthorList
      changeCurrentValue={(val) => {
        setFieldValue(field.name, val.code);
      }}
      selectedOption={field.value}
    />
  );
};

const AddArticleForm = () => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [templateBody, setTemplateBody] = useState("");

  function successSubmission() {
    window.location.href = "/articles";
  }

  function failSubmission(head, sub) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  function templateBodyChange(value) {
    setTemplateBody(value);
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="">
            <Formik
              initialValues={{
                title: "",
                slug: "",
                quote: "",
                summary: "",
                keywords: "",
                headImg: "",
                addToGoogleSearch: "ADD",
                authorId: "",
                offerUrl: "",
                snippetId: "",
                s1_rating: 0,
                s2_rating: 0,
                s3_rating: 0,
                s4_rating: 0,
                s5_rating: 0,
                category: "Available",
                disclaimerHeader: "",
              }}
              validationSchema={Yup.object({
                title: Yup.string()
                  .min(2, "Title cannot smaller than 2 characters")
                  .max(130, "Title cannot exceed 130 characters")
                  .required("Required"),
                slug: Yup.string().min(2, "Slug cannot smaller than 2 characters").max(200, "Slug cannot exceed 200 characters"),
                quote: Yup.string()
                  .min(10, "Quote cannot smaller than 10 characters")
                  .max(250, "Quote cannot exceed 250 characters")
                  .required("Required"),
                summary: Yup.string()
                  .min(10, "Summary cannot smaller than 10 characters")
                  .max(250, "Summary cannot exceed 250 characters")
                  .required("Required"),
                offerUrl: Yup.string()
                  .matches(
                    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                    "Enter correct url!"
                  )
                  .min(10, "Offer Url cannot smaller than 10 characters")
                  .max(250, "Offer Url cannot exceed 250 characters")
                  .required("Required"),
                keywords: Yup.string()
                  .min(2, "Keywords cannot smaller than 2 characters")
                  .max(100, "Keywords cannot exceed 100 characters")
                  .required("Required"),
                headImg: Yup.mixed().required("Img File is required"),
                authorId: Yup.string().required("Required"),
                snippetId: Yup.string().required("Required"),
                addToGoogleSearch: Yup.string().required("Required"),
                s1_rating: Yup.number().required("Required"),
                s2_rating: Yup.number().required("Required"),
                s3_rating: Yup.number().required("Required"),
                s4_rating: Yup.number().required("Required"),
                s5_rating: Yup.number().required("Required"),
                category: Yup.string().required("Required"),
                disclaimerHeader: Yup.string().max(20, "Disclaimer header cannot exceed 20 characters"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                setIsFailSubmission(false);

                addNewArticle({
                  title: values.title,
                  summary: values.summary,
                  quote: values.quote,
                  slug: values.slug,
                  keywords: values.keywords,
                  headImg: values.headImg,
                  addToGoogleSearch: values.addToGoogleSearch,
                  authorId: values.authorId,
                  offerUrl: values.offerUrl,
                  snippetId: values.snippetId,
                  templateBody: templateBody,
                  s1_rating: values.s1_rating,
                  s2_rating: values.s2_rating,
                  s3_rating: values.s3_rating,
                  s4_rating: values.s4_rating,
                  s5_rating: values.s5_rating,
                  category: values.category,
                  disclaimerHeader: values.disclaimerHeader,
                }).then((data) => {
                  try {
                    if (data["result"]) {
                      if (data["result"] === "SUCCESS") {
                        successSubmission(data["data"]);
                      } else if (data["result"] === "FAILED") {
                        failSubmission("¡Error!", data["data"]["errorMessage"]);
                      } else {
                        failSubmission("Error!", "Please try again later!");
                      }
                    } else {
                      failSubmission("Error!", "Please try again later!");
                    }
                  } catch (error) {
                    failSubmission("Error!", "Please try again later!");
                  }
                });
              }}
            >
              <Form>
                <div className="row">
                  <div className="col-sm-8 mb-5">
                    <div className="article-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="form-head-txt">Article Details</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Title</label>
                          <FormGroup className="input-payment-box">
                            <Field name="title" type="text" className="form-control" placeholder="Title" disabled={isLoading} />
                            <ErrorMessage name="title">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Slug (If you leave it blank, it will be generated automatically.)</label>
                          <FormGroup className="input-payment-box">
                            <Field name="slug" type="text" className="form-control" placeholder="Slug" disabled={isLoading} />
                            <ErrorMessage name="slug">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Quote</label>
                          <TextArea name="quote" rows="3" placeholder="Quote" />
                          <ErrorMessage name="quote">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Summary & Description (Meta Tag)</label>
                          <TextArea name="summary" rows="3" placeholder="Summary & Description (Meta Tag)" />
                          <ErrorMessage name="summary">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Keywords (Meta Tag, Use Comma to separate keywords)</label>
                          <FormGroup className="input-payment-box">
                            <Field
                              name="keywords"
                              type="text"
                              className="form-control"
                              placeholder="Keywords (Meta Tag)"
                              disabled={isLoading}
                            />
                            <ErrorMessage name="keywords">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Offer Url</label>
                          <FormGroup className="input-payment-box">
                            <Field name="offerUrl" type="text" className="form-control" placeholder="Offer Url" disabled={isLoading} />
                            <ErrorMessage name="offerUrl">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="article-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="form-head-txt">Review Rating Numbers</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-2 mt-3 mb-1">
                          <label className="control-label">1 Star</label>
                          <FormGroup className="input-payment-box">
                            <Field name="s1_rating" type="number" className="form-control" placeholder="" disabled={isLoading} />
                            <ErrorMessage name="s1_rating">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                        <div className="col-sm-2 mt-3 mb-1">
                          <label className="control-label">2 Star</label>
                          <FormGroup className="input-payment-box">
                            <Field name="s2_rating" type="number" className="form-control" placeholder="" disabled={isLoading} />
                            <ErrorMessage name="s2_rating">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                        <div className="col-sm-2 mt-3 mb-1">
                          <label className="control-label">3 Star</label>
                          <FormGroup className="input-payment-box">
                            <Field name="s3_rating" type="number" className="form-control" placeholder="" disabled={isLoading} />
                            <ErrorMessage name="s3_rating">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                        <div className="col-sm-2 mt-3 mb-1">
                          <label className="control-label">4 Star</label>
                          <FormGroup className="input-payment-box">
                            <Field name="s4_rating" type="number" className="form-control" placeholder="" disabled={isLoading} />
                            <ErrorMessage name="s4_rating">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                        <div className="col-sm-2 mt-3 mb-1">
                          <label className="control-label">5 Star</label>
                          <FormGroup className="input-payment-box">
                            <Field name="s5_rating" type="number" className="form-control" placeholder="" disabled={isLoading} />
                            <ErrorMessage name="s5_rating">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="article-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="form-head-txt">Template Body</p>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="col-sm-12 mt-4 mb-4">
                          <HtmlEditor templateBodyChange={templateBodyChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="article-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12 mb-3">
                          <p>
                            <b>Upload Main post image</b>
                          </p>
                          <FormikUploadImgInput name="headImg" imgSrc="" />
                          <ErrorMessage name="headImg">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                        </div>
                      </div>
                    </div>
                    <div className="article-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Select Snippet</label>
                          <FormGroup className="input-payment-box">
                            <SnippetSelectDropdown className="form-control" name="snippetId" />
                            <ErrorMessage name="snippetId">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Select Author</label>
                          <FormGroup className="input-payment-box">
                            <AuthorSelectDropdown className="form-control" name="authorId" />
                            <ErrorMessage name="authorId">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>{" "}
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Category</label>
                          <FormGroup className="input-payment-box">
                            <Field name="category" as="select" className="form-control" placeholder="Category" disabled={isLoading}>
                              <option value="Available">Available</option>
                              <option value="Sold Out">Sold Out</option>
                            </Field>
                            <ErrorMessage name="category">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Disclaimer Header</label>
                          <FormGroup className="input-payment-box">
                            <Field
                              name="disclaimerHeader"
                              type="text"
                              className="form-control"
                              placeholder="Disclaimer Header"
                              disabled={isLoading}
                            />
                            <ErrorMessage name="disclaimerHeader">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mb-1">
                          <label className="control-label">Add to Google Search</label>
                          <FormGroup className="input-payment-box">
                            <Field name="addToGoogleSearch" as="select" className="form-control" placeholder="Select" disabled={isLoading}>
                              <option value="ADD">Add</option>
                              <option value="DONT">Don't Add</option>
                            </Field>
                            <ErrorMessage name="addToGoogleSearch">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="article-details-div p-3">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="form-head-txt">Publish</p>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="col-sm-8 mt-4 mb-4">
                          {isFailSubmission && (
                            <div className="container">
                              <div className="row">
                                <div className="col text-center">
                                  <>
                                    <Alert color="danger">
                                      <p className="mb-0">{errorMsg.sub}</p>
                                    </Alert>
                                  </>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="container">
                            <div className="row d-flex justify-content-center">
                              <div className="col-sm-12 text-center">
                                <button type="submit" className="button-submit-style" disabled={isLoading}>
                                  Add Post
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddArticleForm;
